import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Rectangle, Tooltip } from 'recharts';

const CustomBarChart = ({ selectedMonth, chartData, fetchBarData }) => {
  const [highlightedBar, setHighlightedBar] = useState(null);

  // Function to handle the click event on the bar
  const handleBarClick = async (data, index) => {
    const { fullDate, CId } = data;

    // Call the passed fetchBarData function from parent
    await fetchBarData(fullDate, CId); // This will call the parent's API function

    // Highlight the clicked bar by setting the index
    setHighlightedBar(index);
  };

  return (
    <div
      style={{
        width: '96%',
        height: '222px',
        borderRadius: '15px',
        background: 'linear-gradient(45deg, rgb(23, 99, 61), rgb(27, 60, 47))',
        padding: '10px',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          barCategoryGap="12%"
          margin={{ top: 10, right: 0, left: -24, bottom: 0 }}
        >
          {/* Y-Axis */}
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#FFFFFF', fontSize: 12 }}
            domain={[0, 'auto']}
          />

          {/* X-Axis */}
          <XAxis
            dataKey="date"
            tick={{ fill: '#FFFFFF', fontSize: 12 }}
            tickLine={false}
            axisLine={false}
            interval={0}
          />

          {/* Tooltip */}
          <Tooltip cursor={{ fill: 'rgba(255, 255, 255, 0.1)' }} />

          {/* Bars */}
          <Bar
            dataKey="value"
            onClick={(data, index) => handleBarClick(data, index)} // Add click event to each bar
            shape={(props) => {
              const { x, y, width, height, index } = props;
              // Set the bar color based on whether it's the clicked bar
              const fillColor = index === highlightedBar ? '#4CAF50' : '#FFFFFF';
              return (
                <Rectangle
                  x={x}
                  y={y}
                  width={width}
                  height={height}
                  radius={[5, 5, 0, 0]}
                  fill={fillColor} // Set the fill color based on highlightedBar
                />
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
