import React, { useContext, useEffect, useState } from 'react';
import {
  Typography, TextField, Table, TableBody, TableCell, TableContainer,TableHead, TableRow, Paper,Box,Stack,InputAdornment} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import calenderImg from '../images/uis_calender.png';
import searchIcon from '../images/search-icon.png';
import ploygenImg from "../images/Polygon.png";
import arrowImg from '../images/arrowicon.png';
import admin_icon from '../images/person.png'
import settings_icon from '../images/settings.png'
import notification_icon from '../images/notification.png'
import Sidebar from '../components/Sidebar';
import axios from 'axios';
import { AuthContext } from '../Context/AuthContext';
import Config from '../utils/Config';
import { throttle } from 'lodash';

const Pharmacies = () => {
  const [pharmacyListData, setPharmacyListData] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [hasMore, setHasMore] = useState(true); 
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [fromOpen, setFromOpen] = useState(false);
  const { apiToken } = useContext(AuthContext);
  const [sortOrder, setSortOrder] = useState('asc');

  const GetPharmaciesList = async (page, searchTerm) => {
    try {
      const response = await axios.get(Config?.Get_Pharmacies_list, {
        params: { page ,
          search: searchTerm,
        },
        headers: { Authorization: `Bearer ${apiToken}` },
      });

      if (response.status === 200) {
        const newData = response?.data?.apiData;
        setPharmacyListData(prevData => [...prevData, ...newData]); // Append new data
        setHasMore(newData.length > 0); // If no data is returned, set hasMore to false
      } else {
        console.log('Something went wrong');
      }
    } catch (error) {
      console.log('Error in fetching API response:', error);
    }
  };


  // const handleDateChange = (newDate) => {
  //   if (newDate) {
  //     const formattedDate = newDate.format("YYYY-MM-DD");
  //     setSelectedDate(formattedDate);
  //   }
  //   setFromOpen(false);
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPharmacies = pharmacyListData.filter(pharmacy =>
    (pharmacy.name.toLowerCase().includes(searchTerm)) ||
    (pharmacy.address.toLowerCase().includes(searchTerm)) ||
    (pharmacy.pharmacyId && pharmacy.pharmacyId.toString().toLowerCase().includes(searchTerm))  // Fix here
  );

  const handleSortOrderValue = () => {
    const sortedOrders = [...pharmacyListData].sort((a, b) => {
      return sortOrder === 'asc' ? a.grossOrderValue - b.grossOrderValue : b.grossOrderValue - a.grossOrderValue;
    });
    setPharmacyListData(sortedOrders);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };


  const handleScroll = throttle((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore) {
      setPage(prevPage => prevPage + 1); // Increment page number to load next page
    }
  }, 500);
  useEffect(() => {
    // Fetch pharmacies when page changes or searchTerm changes
    if (hasMore) {
      GetPharmaciesList(page, searchTerm); // Pass searchTerm as an argument
    }
  }, [page, hasMore, searchTerm]);

  const formatCName = (cName) => {
    if (!cName) return "";
    // Remove extra characters (like numbers and parentheses)
    // const cleanedName = cName.replace(/\(.*?\)|\d+/g, "").trim();
    // Capitalize the first letter of each word
    return cName
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

const formatAddress = (address) => {
    if (!address) return "";
    // Convert to lowercase and capitalize the first letter of each word
    return address
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
};

const formatContactNumber = (contactNumber) => {
  if (!contactNumber) return "";
  // Remove the '/' character from the contact number
  return contactNumber.replace(/\//g, "").trim();
};

  return (
    <>
      <Box display='flex' padding='0px 20px 20px 10px' sx={{backgroundColor: '#f9f9f9'}}>
        <Sidebar />
        <Box sx={{ px: '16px', width: '100%' }}>
          <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
            <Box pt='16px'>
              <Box flexDirection='row' display='flex' alignItems='center' gap='3px'>
                <Typography variant="h6" sx={{ color: '#a0aec0', fontSize: '14px' }}>
                  Pages
                </Typography>
                <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>/</Typography>
                <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>Pharmacies</Typography>
              </Box>
              <Typography mt={1} fontSize='14px' fontWeight='bold' color="#2D3748">Pharmacies</Typography>
            </Box>
            <Box p={'16px'} display='flex' gap='20px'>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display="flex" alignItems="center" gap='16px'>
                  <DatePicker
                    value={dayjs(selectedDate)}
                    onChange={handleDateChange}
                    open={fromOpen}
                    onOpen={() => setFromOpen(true)}
                    onClose={() => setFromOpen(false)}
                    slots={{
                      textField: TextField,
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFromOpen(true);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <Box
                                component="img"
                                src={calenderImg}
                                alt="calendar_icon"
                                sx={{
                                  width: "20px",
                                  height: "20px",
                                  objectFit: "contain",
                                }}
                              />
                            </InputAdornment>
                          ),
                        },
                        sx: {
                          color: "white",
                          backgroundColor: "#71CA9B",
                          borderRadius: "15px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            color: "white",
                            height: "39px",
                            width: "161px",
                            fontSize: "14px",
                            fontWeight: "bold",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider> */}

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#ffffff",
                  padding: "5px 10px",
                  borderRadius: "15px",
                  border: '1px solid #E2E8F0',
                  width: '250px',
                  height: '28px'
                }}
              >
                <Box component={'img'} src={searchIcon} />
                <input
                  placeholder="Search Pharmacy"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  style={{
                    border: "none",
                    background: "transparent",
                    marginLeft: "10px",
                    outline: "none",
                    color: 'gray.400'
                  }}
                />
              </Box>
              <Box display='flex' gap='20px' alignItems='center'>
                <Stack direction='row' alignItems={'baseline'} gap='3px'>
                  <Box component={'img'} src={admin_icon} alt='user' sx={{ width: '12px', height: '12px' }} />
                  <Typography fontSize='12px' fontWeight='bold' color='#718096'>Admin</Typography>
                </Stack>
                <Box component={'img'} src={settings_icon} alt='settings' sx={{ width: '12px', height: '12px' }} />
                <Box component={'img'} src={notification_icon} alt='notification' sx={{ width: '12px', height: '12px' }} />
              </Box>
            </Box>
          </Stack>

          <Box sx={{ marginTop: "20px", backgroundColor: '#ffffff', p: '24px', borderRadius: '15px' }}>
            <Typography variant="h5" sx={{ marginBottom: "10px", fontWeight: 'bold', fontSize: '18px', color: '#2D3748', pl: '15px' }}>
              Pharmacy List
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="tableheadcell" sx={{ width: "8%" }}>PO Code</TableCell>
                    <TableCell className="tableheadcell" sx={{ width: "38%" }}>Pharmacy Name</TableCell>
                    {/* <TableCell className="tableheadcell" sx={{ width: "24%"}}>Location</TableCell> */}
                    <TableCell className="tableheadcell"  sx={{ width: "13%" }}>Contact</TableCell>
                    <TableCell className="tableheadcell" sx={{width: '11%'}}>Gross Order Value <span onClick={handleSortOrderValue} style={{ cursor: 'pointer', userSelect: 'none' }}>
                      <Box component={'img'} src={ploygenImg} alt="filter-arrow" ml='3px' sx={{
                        transform: sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s'
                      }}/></span></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <Box  onScroll={handleScroll} sx={{
                maxHeight: '410px', overflowY: 'auto', '&::-webkit-scrollbar': {
                  width: '8px',
                  height: '12px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#d9d9d9',
                  borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#4A5568',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#fff',
                },
              }}>
                <Table>
                  <TableBody >
                    {filteredPharmacies.map((row, index) => (
                      <TableRow key={index} sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}>
                        <TableCell className="tablerowcell" sx={{ width: "8%" }}>{row.pharmacyId}</TableCell>
                        <TableCell className="tablerowcell" sx={{ width: "38%", fontFamily: 'Helvetica', letterSpacing: '0px', fontSize: '14px !important' }}>
                          <span style={{width: '100%', display: 'block'}}>{formatCName(row.name)}</span>
                          <Typography fontSize='14px' sx={{ color: '#718096' }} variant="body2" color="textSecondary" fontFamily="Helvetica" fontWeight='400'>
                          {formatAddress(row.address)}
                          </Typography>
                          </TableCell>
                        {/* <TableCell className="tablerowcell" sx={{ width: "24%", textAlign: 'center', fontFamily: 'Helvetica', letterSpacing: '0px', fontSize: '13px !important' }}><span style={{width: '75%', display: 'block'}}>{formatAddress(row.address)}</span></TableCell> */}
                        <TableCell className="tablerowcell" sx={{ fontWeight: 'normal !important', width: "9%" }}>{formatContactNumber(row.contactNumber)}</TableCell>
                        {/* <TableCell className="tablerowcell" >{getStatusChip(row.status)}</TableCell> */}
                        <TableCell className="tablerowcell" sx={{width: '10%'}}>{row.grossOrderValue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Pharmacies;
