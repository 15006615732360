import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import ajantaPharma from "../../images/website-images/image_272-removebg-preview.png"
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import Config from "../../utils/Config";
import { useParams } from "react-router-dom";

const data1 = [

  { sNo: 1, code: 121, name: "Aqualube eye drop", pack: 121, mrp: 121, company: "Aqualube eye drop" },
  { sNo: 2, code: 222, name: "Aqualube Liquigel", pack: 222, mrp: 222, company: "Aqualube Liquigel" },
  { sNo: 3, code: 334, name: "Besix Drop", pack: 334, mrp: 334, company: "Besix Drop" },
  { sNo: 4, code: 456, name: "Betoact eye drop", pack: 456, mrp: 456, company: "Betoact eye drop" },
  { sNo: 5, code: 555, name: "Gate DM eye drop", pack: 555, mrp: 555, company: "Gate DM eye drop" },
  { sNo: 6, code: 632, name: "Gate eye drop", pack: 632, mrp: 632, company: "Gate eye drop" },
  { sNo: 7, code: 745, name: "Gate HS Drop", pack: 745, mrp: 745, company: "Gate HS Drop" },
  { sNo: 8, code: 821, name: "Gate P Eye Drop", pack: 821, mrp: 821, company: "Gate P Eye Drop" },
  { sNo: 9, code: 956, name: "Hypoclin Solution", pack: 956, mrp: 956, company: "Hypoclin Solution" },
  { sNo: 10, code: 104, name: "Ketogate Drop", pack: 104, mrp: 104, company: "Ketogate Drop" },
  { sNo: 11, code: 115, name: "Lotegate Drop", pack: 115, mrp: 115, company: "Lotegate Drop" },
  { sNo: 12, code: 126, name: "Lotel Eye Gel", pack: 126, mrp: 126, company: "Lotel Eye Gel" },
  { sNo: 13, code: 137, name: "Lotel LS Gel", pack: 137, mrp: 137, company: "Lotel LS Gel" },
  { sNo: 14, code: 148, name: "Lotel OinT 5GM", pack: 148, mrp: 148, company: "Lotel OinT 5GM" },
  { sNo: 15, code: 153, name: "Maxmoist Eye Drops", pack: 153, mrp: 153, company: "Maxmoist Eye Drops" },
  { sNo: 16, code: 167, name: "Maxmoist Ultra Eye Drop", pack: 167, mrp: 167, company: "Maxmoist Ultra Eye Drop" },
  { sNo: 17, code: 171, name: "Nepaflam Drop", pack: 171, mrp: 171, company: "Nepaflam Drop" },
  { sNo: 18, code: 183, name: "Nepaflam OD", pack: 183, mrp: 183, company: "Nepaflam OD" },
  { sNo: 19, code: 195, name: "Ocugold Plus Tab", pack: 195, mrp: 195, company: "Ocugold Plus Tab" },
  { sNo: 20, code: 201, name: "Virson Gel", pack: 201, mrp: 201, company: "Virson Gel" },
];

const data2 = [

  { sNo: 1, code: 121, name: "Aqualube eye drop", pack: 121, mrp: 121, company: "Aqualube eye drop" },
  { sNo: 2, code: 222, name: "Aqualube Liquigel", pack: 222, mrp: 222, company: "Aqualube Liquigel" },
  { sNo: 3, code: 334, name: "Besix Drop", pack: 334, mrp: 334, company: "Besix Drop" },
  { sNo: 4, code: 456, name: "Betoact eye drop", pack: 456, mrp: 456, company: "Betoact eye drop" },
  { sNo: 5, code: 555, name: "Gate DM eye drop", pack: 555, mrp: 555, company: "Gate DM eye drop" },
  { sNo: 6, code: 632, name: "Gate eye drop", pack: 632, mrp: 632, company: "Gate eye drop" },
  { sNo: 7, code: 745, name: "Gate HS Drop", pack: 745, mrp: 745, company: "Gate HS Drop" },
  { sNo: 8, code: 821, name: "Gate P Eye Drop", pack: 821, mrp: 821, company: "Gate P Eye Drop" },
  { sNo: 9, code: 956, name: "Hypoclin Solution", pack: 956, mrp: 956, company: "Hypoclin Solution" },
  { sNo: 10, code: 104, name: "Ketogate Drop", pack: 104, mrp: 104, company: "Ketogate Drop" },
  { sNo: 11, code: 115, name: "Lotegate Drop", pack: 115, mrp: 115, company: "Lotegate Drop" },
  { sNo: 12, code: 126, name: "Lotel Eye Gel", pack: 126, mrp: 126, company: "Lotel Eye Gel" },
  { sNo: 13, code: 137, name: "Lotel LS Gel", pack: 137, mrp: 137, company: "Lotel LS Gel" },
  { sNo: 14, code: 148, name: "Lotel OinT 5GM", pack: 148, mrp: 148, company: "Lotel OinT 5GM" },
  { sNo: 15, code: 153, name: "Maxmoist Eye Drops", pack: 153, mrp: 153, company: "Maxmoist Eye Drops" },
  { sNo: 16, code: 167, name: "Maxmoist Ultra Eye Drop", pack: 167, mrp: 167, company: "Maxmoist Ultra Eye Drop" },
  { sNo: 17, code: 171, name: "Nepaflam Drop", pack: 171, mrp: 171, company: "Nepaflam Drop" },
  { sNo: 18, code: 183, name: "Nepaflam OD", pack: 183, mrp: 183, company: "Nepaflam OD" },
  { sNo: 19, code: 195, name: "Ocugold Plus Tab", pack: 195, mrp: 195, company: "Ocugold Plus Tab" },
  { sNo: 20, code: 201, name: "Virson Gel", pack: 201, mrp: 201, company: "Virson Gel" },
];


const ProductList = () => {

  const datasets = [
    { title: "Ajanta (Illuma)", data: data1 },
    { title: "Ajanta (Illuma)", data: data2 },
  ];


  const [products, setProducts] = useState([]);
  const { apiToken } = useContext(AuthContext);
  const { id } = useParams();

  const formatCName = (cName) => {
    if (!cName) return "";

    return cName
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const GetProducts = async () => {
    try {
      const response = await axios.post(Config?.Get_Products, {
        id: id
      }, {
        headers: {
          Authorization: `Bearer ${apiToken}`
        }
      })
      if (response?.status === 200) {
        setProducts(response?.data)
        console.log(response?.data, 'products')
      }
    } catch (error) {
      console.log(error, "Erorr in Fetching API response")
    }
  }

  useEffect(() => {
    GetProducts()
  }, [])
  console.log(id, 'mfgcompname')

  return (
    <Box sx={{ marginBottom: '2rem' }}>
      <Box sx={{ width: { xs: '100%', sm: '88%', md: '88%' }, margin: { md: 'auto' }, padding: { md: 3 } }}>
        <Box sx={{ paddingTop: { xs: '2rem', sm: '' }}}>
          <Stack direction='row' sx={{
            background: "linear-gradient(45deg, #53A57A, #518369)", padding: {xs:'1rem',sm: '1rem 3rem',md:'1rem 3rem'}, borderRadius: '15px', mb: {xs: '2.5rem', sm: '3rem',md:'3rem'}, position: 'relative', width: { xs: '80%', sm: '87%' }, mx: { xs: 'auto', sm: 'auto' },
            // "&::before": {
            //   content: '""',
            //   width: "1px", 
            //   height: "75px", 
            //   backgroundColor: "#eaeaea",
            //   position: "absolute",
            //   top: "50%",
            //   left: "16%",
            //   transform: "translateY(-50%)", 
            // }
          }}>
            {/* <Box component={'img'} src={ajantaPharma} alt="" width='120px' /> */}
            <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
              <Typography variant="h5" fontWeight="bold" align="center" sx={{ color: 'white', fontFamily: "'GeneralSansSemibold', sans-serif", fontSize: { xs: '16px', md: '30px' } }}>
                {id}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box mb={4} sx={{ width: { xs: '95%', sm: '94%' }, marginLeft: { xs: 'auto' }, margin: { sm: 'auto' } }}>
        {Object.entries(products).map(([divisionName, productList], divisionIndex) => (
  <Box key={divisionIndex} mb={4}>
    {/* Division Title */}
    <Typography
      variant="h6"
      sx={{
        backgroundColor: "#539D77",
        color: "white",
        padding: "4px 0px 4px 20px",
        borderRadius: {xs:"4px 0px 0px 4px", sm: '4px',md:"4px"},
        fontSize: { xs: "16px", sm: "24px", md: "24px" },
        
        mb: 2,
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        whiteSpace: "nowrap", 
      }}
    >
      {formatCName(divisionName)}
    </Typography>

    {/* Product Table */}
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#D1E0D8" }} className="productTable">
            <TableCell className="productTableheadcell" sx={{ color: "#539D77", fontSize: { xs: "14px", sm: "15px", md: "15px" } }}>
              S.no
            </TableCell>
            <TableCell className="productTableheadcell" sx={{ color: "#539D77", fontSize: { xs: "14px", sm: "15px", md: "15px" } }}>
              Product Name
            </TableCell>
            <TableCell className="productTableheadcell" sx={{ color: "#539D77", fontSize: { xs: "14px", sm: "15px", md: "15px" } }}>
              Pack
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productList.map((product, productIndex) => (
            <TableRow key={productIndex}>
              <TableCell className="productTablecell" sx={{ fontSize: { xs: "13px", sm: "15px", md: "15px" } }}>
                {productIndex + 1}
              </TableCell>
              <TableCell className="productTablecell" sx={{ fontSize: { xs: "13px", sm: "15px", md: "15px" } }}>
                {formatCName(product.productName)}
              </TableCell>
              <TableCell className="productTablecell" sx={{ fontSize: { xs: "13px", sm: "15px", md: "15px" } }}>
                {product.packing}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
))}
        </Box>
      </Box>
    </Box>
  );
};


export default ProductList;
