import React, { useContext } from "react";
import { Stepper, Step, StepLabel, StepConnector, Box, Divider } from "@mui/material";
import { styled } from "@mui/system";
import bill_img from "../images/bill.png";
import bill_img_grey from "../images/bill-in-grey.png";
import order_dispatch from "../images/dispatch.png";
import order_dispatch_grey from "../images/dispatch-in-grey.png";
import clock_truck_grey from "../images/truck-with-circular-clock-grey.png";
import outForDelivery from "../images/fast-delivery-grey.png";
import outForDeliverywhite from "../images/fast-delivery-white.svg";
import clock_truck_white from "../images/clock-truck-white.svg";
import delivered from "../images/delivered_grey.png";
import delivered_white from "../images/delivered_white.svg";
import ShippingContainer from "../components/ShippingProcess/ShippingContainer";
import axios from "axios";
import Config from "../utils/Config";
import { AuthContext } from "../Context/AuthContext";

// Solid connector styling
const SolidConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderWidth: 4,
    borderRadius: 1,
    borderColor: "#9F9F9F",
  },
  "&.Mui-active .MuiStepConnector-line, &.Mui-completed .MuiStepConnector-line": {
    borderColor: "#71ca9b",
  },
  top: '18px',
  left: 'calc(-50% + 22px)',
}));

// Dotted divider styling
const DottedDivider = styled(Divider)(({ theme, isActive }) => ({
  borderStyle: "dashed",
  borderWidth: 2,
  borderColor: isActive ? "#71CA9B" : "#9F9F9F", // Change color dynamically
  margin: "10px 0",
  width: "5%",
  alignSelf: "center",
  position: "relative",
  top: "-15px",
}));

// Custom StepIcon component
const StepIcon = ({ active, completed, icon, greyIcon }) => {
  const iconToShow = completed || active ? icon : greyIcon;
  return (
    <Box
      display="flex"
      padding="11px"
      borderRadius="15px"
      bgcolor={completed || active ? "#71CA9B" : "#ffffff"}
      boxShadow={completed || active ? "none" : "1px 2px 3px #d3d3d3"}
    >
      <Box component="img" src={iconToShow} alt="Step Icon" sx={{ width: 21, height: 21 }} />
    </Box>
  );
};

export default function DeliveryProgressTracker({ orderStatus ,orderNo, driver_id, route_id, route}) {


  const stepsBox1 = [
    { label: "Bill Generated", icon: bill_img, icon_grey: bill_img_grey },
    { label: "Preparing Dispatch", icon: order_dispatch, icon_grey: order_dispatch_grey },
    { label: "Goods Dispatched", icon: clock_truck_white, icon_grey: clock_truck_grey },
  ];

  const stepsBox2 = [
    { label: "Out for Delivery", icon: outForDeliverywhite, icon_grey: outForDelivery },
    { label: "Delivered", icon: delivered_white, icon_grey: delivered },
  ];

  // Get the last status from the provided orderStatus array
  const currentStepLabel = orderStatus
  console.log(currentStepLabel, 'currentsteplabel')

  // Function to get current step based on order status
  const getCurrentStepIndex = (steps, label) => steps.findIndex((step) => step.label === label);

  const currentStepBox1 = getCurrentStepIndex(stepsBox1, currentStepLabel);
  const isStepBox1Complete = currentStepBox1 === -1 || currentStepBox1 === stepsBox1.length;

  const currentStepBox2 = getCurrentStepIndex(stepsBox2, currentStepLabel);
  const isBox2Active = currentStepBox2 !== -1;

  return (
    <>
      <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" position="relative" width="100%">
        {/* First box of steps */}
        <Stepper
          alternativeLabel
          activeStep={currentStepBox1 === -1 ? 0 : currentStepBox1}
          connector={<SolidConnector />}
          sx={{
            padding: "20px 5px",
            borderRadius: "15px",
            boxShadow: "0px 2px 3px #d2d2d2",
            width: "55%",
          }}
        >
          {stepsBox1.map((step, index) => (
            <Step key={index} sx={{ padding: "0px" }} completed={isStepBox1Complete || index < currentStepBox1}>
              <StepLabel
                StepIconComponent={() => (
                  <StepIcon
                    active={index === currentStepBox1}
                    completed={isStepBox1Complete || index < currentStepBox1}
                    icon={step.icon}
                    greyIcon={step.icon_grey}
                  />
                )}
              >
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12px", color: "#A0AEC0", fontFamily: "Helvetica" }}>
                  {step.label}
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* Dotted line between the two boxes */}
        <DottedDivider isActive={isBox2Active} />

        {/* Second box of steps */}
        <Stepper
          alternativeLabel
          activeStep={currentStepBox2 === -1 ? 0 : currentStepBox2}
          connector={<SolidConnector />}
          sx={{
            width: "35%",
            padding: "20px 5px",
            borderRadius: "15px",
            boxShadow: "0px 2px 3px #d2d2d2",
          }}
        >
          {stepsBox2.map((step, index) => (
            <Step key={index} sx={{ padding: '0px' }}>
              <StepLabel
                StepIconComponent={() => (
                  <StepIcon
                    active={index === currentStepBox2}
                    completed={index < currentStepBox2}
                    icon={step.icon}
                    greyIcon={step.icon_grey}
                  />
                )}
              >
                <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12px", color: "#A0AEC0", fontFamily: "Helvetica" }}>
                  {step.label}
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {/* {currentStepLabel === "Goods Dispatched" && ( */}
      {(currentStepLabel === "Delivered" || currentStepLabel === "Out for Delivery") && (
    <Box marginTop="20px">
        <ShippingContainer orderNo={orderNo} driver_id={driver_id} route_id={route_id} route={route} />
    </Box>
)}
    </>
  );
}
