import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import dummy_map from "../../images/website-images/dummy_map.png";
import location_icon from "../../images/website-images/location_pin.png";
import clock_icon from "../../images/website-images/clock.png";
import cellPhone_icon from "../../images/website-images/cell_phone.png";
import AppointmentForm from "./AppointmentForm";
import { ToastContainer } from "react-toastify";

const GetInTouch = () => {
    return (
        <>
            <Box sx={{
                padding: "1.5rem 20px 20px 20px",
                // backgroundColor: {sm: '#f4f4f4'} ,
                borderRadius: "8px",
            }}>
                {/* Header Section */}
                <Box sx={{ marginBottom: "20px", textAlign: { xs: "left", sm: 'center', md: "center" } }}>
                    <Typography sx={{ fontWeight: { xs: 'normal', sm: 'bold', md: "bold" }, color: "#3A3F3C", fontFamily: { xs: "'Kamerik205Regular3', sans-serif", sm: "'GeneralSansSemibold', sans-serif", md: "'GeneralSansSemibold', sans-serif" }, fontSize: '34px' }}>
                        Get in Touch
                    </Typography>
                    <Typography sx={{ color: "#3C4959", marginTop: { md: "7px" }, fontFamily: "'GeneralSansRegular', sans-serif", width: { xs: '73%', sm: '100%' } }}>
                        Become Your Trusted Partner in Medicine Supply
                    </Typography>
                </Box>

                {/* Map Section */}
                <Box sx={{ marginTop: '3rem' }}>
                    <Stack direction={{ xs: 'column-reverse', sm: 'row', md: 'row' }} width={{ xs: '100%', sm: '100%', md: '74%' }} margin='auto' justifyContent='space-between' gap={{ sm: '38px', md: '40px' }}>
                        <Box sx={{ marginTop: { xs: '3rem', sm: '0', md: '0' } }}>
                            <Box sx={{ position: "relative", marginBottom: "20px" }}>
                                <Box
                                    component="img"
                                    src={dummy_map}
                                    alt="map"
                                    sx={{
                                        width: { xs: '100%', md: "485px" },
                                        height: "320px",
                                        borderRadius: "8px",
                                    }}
                                />
                                {/* Address Section */}
                                <Box
                                    sx={{
                                        position: "absolute",
                                        bottom: "20px",
                                        left: { xs: "11px", md: "19px" },
                                        backgroundColor: "#fff",
                                        // padding: "12px",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                                        width: '92.2%'
                                    }}
                                >
                                    <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="flex-start" sx={{ background: '#fff', padding: '1rem', borderRadius: '15px', width: { xs: '90%', sm: '90%', md: '93%' } }}>
                                        <Box>
                                            <Box component="img" src={location_icon} alt="location-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#011632", fontFamily: "'GeneralSansMedium', sans-serif" }}>Anahad Office Address</Typography>
                                            <Typography variant="body2" sx={{ color: "#3C4959", marginTop: "4px", letterSpacing: '0px', fontFamily: "'GeneralSansRegular', sans-serif", fontSize: { xs: '14px', sm: '16px' } }}>
                                                101, LD building, 1st floor, Mehra Industrial Estate, Lal <br />
                                                Bahadur Shastri Marg, Vikhroli West, Mumbai, <br />
                                                Maharashtra 400079
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </Box>

                            {/* Details Section */}
                            <Stack spacing={2}>
                                {/* Office Timings */}
                                <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="center" sx={{ background: '#fff', padding: '1rem', borderRadius: '15px', width: { xs: '90%', sm: '94%', md: '94%' }, boxShadow: '0px 2px 10px 3px #f0f0f0' }}>
                                    <Box component="img" src={clock_icon} alt="clock-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                                    <Box>
                                        <Typography sx={{ color: "#011632", fontFamily: "'GeneralSansMedium', sans-serif" }}>Office Timings</Typography>
                                        <Typography variant="body2" sx={{ color: "#3C4959", marginTop: "4px", fontFamily: "'GeneralSansRegular', sans-serif", fontSize: { xs: '14px', sm: '16px' } }}>
                                            Monday - Saturday (9:00am to 5pm) <br />
                                            Sunday (Closed)
                                        </Typography>
                                    </Box>
                                </Stack>

                                {/* Email Address */}
                                <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="center" sx={{ background: '#fff', padding: '18px 16px', borderRadius: '15px', width: { xs: '90%', sm: '94%', md: '94%' }, boxShadow: '0px 2px 10px 3px #f0f0f0' }}>
                                    <Box component="img" src={location_icon} alt="email-icon" sx={{ width: "20px", height: "20px", padding: '11px', borderRadius: '30px', backgroundColor: '#71CA9B' }} />
                                    <Box>
                                        <Typography sx={{ color: "#011632", fontFamily: "'GeneralSansMedium', sans-serif" }}>Email Address</Typography>
                                        <Typography variant="body2" sx={{ color: "#3C4959", marginTop: "4px", fontFamily: "'GeneralSansRegular', sans-serif", fontSize: { xs: '14px', sm: '16px' } }}>
                                            shikhar@Anahad.in
                                        </Typography>
                                    </Box>
                                </Stack>

                                {/* Phone Number */}
                                <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 2 }} alignItems="center" sx={{ background: '#fff', padding: '18px 16px', borderRadius: '15px', width: { xs: '90%', sm: '94%', md: '94%' }, boxShadow: '0px 2px 10px 3px #f0f0f0' }}>
                                    <Box sx={{ padding: '10px 11px 9px 12px', borderRadius: '30px', backgroundColor: '#71CA9B', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '23px' }}>
                                        <Box component="img" src={cellPhone_icon} alt="phone-icon" sx={{ width: "20px", height: "20px" }} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ color: "#011632", fontFamily: "'GeneralSansMedium', sans-serif" }}>Phone Number</Typography>
                                        <Typography variant="body2" sx={{ color: "#3C4959", marginTop: "4px", fontFamily: "'GeneralSansRegular', sans-serif", fontSize: { xs: '14px', sm: '16px' } }}>
                                            +91 88222-88222
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Box>

                        <Box width={{ xs: '98%', sm: '53%', md: '53%' }}>
                            <AppointmentForm />
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <ToastContainer />
        </>
    );
};

export default GetInTouch;
