import React, { useState } from "react";
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Divider } from "@mui/material";
import view_more from "../../images/website-images/plus_icon.svg";
import view_less from "../../images/website-images/view_less_icon.svg";

const FAQSection = () => {
  const [expanded, setExpanded] = useState(); // Set default expanded panel to "panel1"

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: {xs: "87%", sm: '65%',md:"65%"},
        mx: "auto",
        my: 6,
        p: {xs: '12px 16px',sm:"2rem 3rem",md:"2rem 3rem"},
        borderRadius: "12px",
        bgcolor: "#ffffff",
        boxShadow: "rgb(186 186 186 / 10%) 0px 2px 6px",
      }}
    >
      {/* Heading */}
      <Typography
        textAlign={{xs: 'left',md:"center"}}
        mb={2}
        color="#3A3F3C"
        sx={{ fontSize: {xs: '30px',md:"32px"}, fontFamily: "'GeneralSansSemibold', sans-serif", lineHeight: {xs: '40px'}, paddingLeft: {xs: '6px'} }}
      >
        Frequently Ask Question
      </Typography>
      <Typography
        textAlign={{xs: 'left',md:"center"}}
        mb={4}
        color="#011632"
        sx={{ fontSize: "15px", lineHeight: "1.5", fontFamily: "'GeneralSansRegular', sans-serif", paddingLeft: {xs: '6px'} }}
      >
        We use only the best quality materials on the market in order to provide the best
        products to our patients.
      </Typography>

      {/* FAQ Items */}
      {[
        {
          panel: "panel1",
          question: "How does Anahad ensure timely delivery of medicines?",
          answer:
            "We operate through an advanced logistics network and optimized processes to ensure medicines reach your pharmacy quickly and efficiently.",
        },
        {
          panel: "panel2",
          question: "What types of medicines and brands are available through Anahad?",
          answer:
            "We offer a wide range of medicines and brands to cater to your specific needs.",
        },
        {
          panel: "panel3",
          question: "Is there a minimum order requirement to place an order with Anahad?",
          answer:
            "Yes, a minimum order requirement applies depending on your location and order size.",
        },
        {
          panel: "panel4",
          question: "How can I track the status of my orders?",
          answer:
            "You can track the status of your orders through our online tracking system or by contacting customer support.",
        },
      ].map(({ panel, question, answer }, index) => (
        <Accordion
          key={panel}
          expanded={expanded === panel}
          onChange={handleChange(panel)}
          sx={{
            mb: 2,
            borderRadius: expanded === panel ? "12px !important" : '0px',
            overflow: "hidden",
            // Apply the border-bottom conditionally for panel 2 and 3
            borderBottom: index === 0 || index === 1 || index === 2 ? "1px solid #e0e0e0" : "none",
            boxShadow: expanded === panel ? "none" : "none",
            width: expanded === panel ? '96%' : '90%',
            margin: expanded === panel ? 'auto !important' : 'auto',
            padding: expanded === panel ? '' : '10px 0px 13px'
          }}
        >
          <AccordionSummary
            expandIcon={
              expanded === panel ? (
                <Box component={"img"} src={view_less} alt="minus_icon" width={"19px"} height={"19px"} paddingLeft={{md:"18px"}} />
              ) : (
                <Box component={"img"} src={view_more} alt="view_more" width={"19px"} height={"19px"} />
              )}
            sx={{
              bgcolor: expanded === panel ? "#60BC8C" : "#ffffff",
              color: expanded === panel ? "#ffffff" : "#333333",
              minHeight: expanded === panel ? '60px' : '51px',
              padding: '0px 16px 0px 0px !important',
              "& .MuiAccordionSummary-content": {
                fontSize: "16px",
                margin: "0px !important",
                paddingRight: "30px !important",
                padding: '0 !important'
              },}}>
            <Typography sx={{ fontFamily: "'GeneralSansMedium', sans-serif", letterSpacing: "0px", 
              paddingLeft: expanded === panel ? {xs: '20px',sm: '3rem',md:'3rem'} : '0'
             }}>
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: expanded === panel ? "#60BC8C" : "#ffffff",
              color: expanded === panel ? "#ffffff" : "#666666",
              p: {xs: "0px 20px 20px 20px",sm: "0px 30px 35px 45px", md:"0px 30px 35px 45px"},
              fontSize: "14px",
              lineHeight: "1.6",
            }}
          >
            <Box sx={{ width: "100%", marginBottom: "16px" }}>
              <Divider
                orientation="horizontal"
                style={{
                  borderWidth: "1.5px",
                  borderColor: "#FFFFFF",
                  border: 'none',
                  borderBottom: '1px solid',
                  display: expanded ? "block" : 'none'
                }}
              />
            </Box>
            <Typography
              sx={{
                fontFamily: "'GeneralSansRegular', sans-serif",
                fontSize: "15px",
                letterSpacing: "0.1px",
                wordSpacing: "1px",
                width: "96%",
              }}
            >
              {answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
