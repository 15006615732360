import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import axios from "axios";
import Config from "../../utils/Config";
import { AuthContext } from "../../Context/AuthContext";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

const GradientChart = () => {

  const [selectedYear, setSelectedYear] = useState(2025);
  const [salesData, setSalesData] = useState([])
  const [monthlyData, setMonthlyData] = useState([])
  const {apiToken} = useContext(AuthContext);

  const GetSalesOverviewData = async()=>{
    try{
       const response = await axios.post(Config?.Sales_Overview,{
        year: selectedYear
       },{
        headers: {
          Authorization: `Bearer ${apiToken}`
        }
       })

       if(response?.status === 200){
        const Salesdata = response?.data?.apiData;
        const monthlySaleData = response?.data?.apiData?.monthlyData;

        setSalesData(Salesdata);
        setMonthlyData(monthlySaleData)
        console.log(Salesdata, 'salesOverviewData');
        console.log(monthlySaleData, 'monthlySalesData');
       }else{
        console.log('Something went wrong');
       }
    }catch(error){
       console.log(error, 'Error in fetching response...')
    }
  }

  const MonthlySalesAmount = monthlyData.map((item) => item.totalDeliveredAmount || 0);

  console.log(MonthlySalesAmount, 'monthlySalesAmountt')

  useEffect(()=>{
    GetSalesOverviewData()

    const IntervalId = setInterval(()=>{
      GetSalesOverviewData()
    }, 300000)

    return ()=> clearInterval(IntervalId)
  }, [selectedYear])

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "2021",
        data: MonthlySalesAmount,
        borderColor: "#00C49F", 
        borderWidth: 2,
        pointRadius: 0,
        tension: 0.4, 
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, "rgba(113, 202, 155, 0.3)");
          gradient.addColorStop(1, "rgba(113, 202, 155, 0)");
          return gradient;
        },
        fill: true, 
      },
      // {
      //   label: "2020",
      //   data: [250, 300, 250, 260, 200, 260, 250, 280, 230, 220, 240, 300],
      //   borderColor: "#333333", 
      //   borderWidth: 2,
      //   pointRadius: 0,
      //   tension: 0.4,
      //   backgroundColor: (context) => {
      //     const chart = context.chart;
      //     const { ctx, chartArea } = chart;

      //     if (!chartArea) {
      //       return null;
      //     }

      //     const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
      //     gradient.addColorStop(0, "rgba(51, 51, 51, 0.3)");
      //     gradient.addColorStop(1, "rgba(51, 51, 51, 0)");
      //     return gradient;
      //   },
      //   fill: true,
      // },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, 
        },
        ticks: {
          color: "#999",
        },
      },
      y: {
        grid: {
          drawBorder: false, 
          color: "rgba(200, 200, 200, 0.3)", 
        },
        ticks: {
          beginAtZero: true,
          color: "#999", 
        },
        min: 0,
      },
    },
  };

  return (
    <Box padding={'32px 0px 32px 32px'}>
      <Stack direction='row' width='100%' justifyContent='space-between'>
        <Box>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: '#2d3748' }}>Sales Overview</Typography>
          <Typography sx={{ fontSize: '14px' }}><span style={{ color: '#66BB6A' }}>(+{salesData?.deliveredIncrease}) more </span><span style={{ color: '#BDBDBD' }}>in {selectedYear} </span></Typography>
        </Box>
        <Box width='15%'>
        <FormControl sx={{ width: '100%' }}>
        <Select
          value={selectedYear}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Year' }}
          sx={{
            border: 'none', 
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none', 
            },
          }}
        >
          <MenuItem value={2025}>2025</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
        </Select>
      </FormControl>
        </Box>
      </Stack>
      <div style={{ width: "100%", height: "400px", paddingTop: '18px' }}>
        <Line data={data} options={options} />
      </div>
    </Box>
  );
};

export default GradientChart;
