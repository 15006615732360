import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import shipping_truck_img from "../../images/website-images/delivery_bike.svg";
import round_bar from "../../images/round_bar.png";
import location_bar from "../../images/location_bar.png";
import vertical_line from "../../images/line.png";
import call from "../../images/call.png";
import text_msg from "../../images/message-text.png";
import map from "../../images/CardShipment.png";
import MyMapComponent from "../../services/MapContainer";
import MapWithDirections from "../../services/MapContainer";

const ShippingContainer = ({orderNo,  driver_id, route_id,route}) => {
    const origin = { lat: 19.2183, lng: 72.9781 }; // Example: Vikhroli
    const destination = { lat: 19.0607, lng: 72.8371 }; // Example: Bandra
    return (
        <>
            <Box display='flex' gap='10px'>
                <Box border='2px solid #71CA9B' borderRadius='15px' padding='15px 15px 0px' height='337px' boxShadow='0px 2px 35px 4px #e3e3f3' width='288px'>
                    <Box pb={'10px'}>
                        <Stack direction='row' justifyContent='space-between'>
                            <Box>
                                <Typography fontSize='14px' fontWeight={600} color="#B0B0B0" >Shipment number</Typography>
                                <Typography fontSize='18px' fontWeight='bold' color="#232323">EV-2017002346</Typography>
                                <Typography color="#4E5848" fontSize='14px'>Medicines</Typography>
                            </Box>
                            <Box component={'img'} src={shipping_truck_img} alt="shipping_truck" width='152px' height='58px' />
                        </Stack>
                    </Box>
                    <Divider sx={{ borderColor: '#ececec' }} />

                    <Box py={'16px'}>
                        <Stack direction='row' gap='16px'>
                            <Box component={'img'} src={round_bar} alt="circle" width='38px' height='38px' />
                            <Box>
                                <Typography fontSize='16px' fontWeight='bold' color="#232323">2684 Maplewood Lane</Typography>
                                <Typography fontSize='12px' color="#B0B0B0">Springfield, IL 62704, USA</Typography>
                            </Box>
                        </Stack>
                        <Box component={'img'} src={vertical_line} alt="dotted_line" width='2px' position='relative' left='18px' />
                        <Stack direction='row' gap='16px'>
                            <Box component={'img'} src={location_bar} alt="location-icon" width='38px' height='38px' />
                            <Box>
                                <Typography fontSize='16px' fontWeight='bold' color="#232323">2684 Maplewood Lane</Typography>
                                <Typography fontSize='12px' color="#B0B0B0">Springfield, IL 62704, USA</Typography>
                            </Box>
                        </Stack>
                    </Box>

                    <Divider sx={{ borderColor: '#E3E3E3' }} />

                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '300px',
                            gap: '10px',
                            pt: '14px'
                        }}
                    >
                        {/* Left: Avatar and Label */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Avatar
                                alt="Darrell Steward"
                                //   src="https://via.placeholder.com/150" // Replace with actual image URL
                                sx={{ width: 56, height: 56 }}
                            />
                        </Box>

                        {/* Middle: Name and Company */}
                        <Box sx={{ flex: 1 }}>
                            <Typography sx={{ fontSize: '14px', color: '#B0B0B0' }}>Client</Typography>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#232323' }}>Darrell Steward</Typography>
                            <Typography sx={{ fontSize: '14px', color: '#4E5848' }}>Mariene, LTD</Typography>
                        </Box>

                        {/* Right: Icons */}
                        <Box sx={{ display: 'flex', gap: 1 }}>

                            <Box component={'img'} src={call} alt="call_icon" padding='10px' bgcolor='##f7f5ff' borderRadius='10px' />
                            <Box component={'img'} src={text_msg} alt="text_message" padding='10px' bgcolor='##f7f5ff' borderRadius='10px' />
                        </Box>
                    </Box>
                </Box>


                <MapWithDirections orderNo={orderNo}  driver_id={driver_id} route_id={route_id} route={route}/>
            </Box>
        </>
    )
}

export default ShippingContainer