import React, { useState } from "react";
import ProductList from "./ProductList";
import Navbar from "../HomeScreen/Navbar";
import Footer from "../HomeScreen/Footer";
import { Box } from "@mui/material";

const ProductDetailsPage = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <>
            <Box sx={{ backgroundColor: '#f6f6f6 !important', display: { xs: 'none', sm: 'block', md: 'block' } }}>
                <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <Box>
                    <ProductList />
                    <Footer />
                </Box>
            </Box>

            <Box sx={{ backgroundColor: '#f6f6f6 !important', display: { xs: 'block', sm: 'none', md: 'none' } }}>
                <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <Box sx={{ marginTop: isDrawerOpen ? "253px" : "0", transition: "margin-top 0.3s ease" }}>
                    <Box sx={{ background: 'radial-gradient(circle at top left, #ade9c952 , #f6fffa30 2%, rgba(255, 255, 255, 0.5) 40%)' }}>
                        <ProductList />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ProductDetailsPage