import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import card1 from "../../images/website-images/Mask.png";
import card2 from "../../images/website-images/Mask group.png";
import card3 from "../../images/website-images/image.png";
import linkdinIcon from "../../images/website-images/LinkedIn.png"

const FeatureCards = () => {
    const features = [
        {
            image: `${card1}`,
            title: "Efficient Delivery:",
            description: "Streamlined supply chain ensuring pharmacies are always stocked.",
            background: 'linear-gradient(122deg, #71CA9B, #c58b7b)'
        },
        {
            image: `${card3}`,
            title: "Trusted Partner:",
            description: "Built on transparency and reliability.",
            background: 'linear-gradient(122deg, #71CA9B, #c8cabf)'
        },
        {
            image: `${card2}`,
            title: "Tech-Enabled Process:",
            description: "Simplified ordering and monitoring for smooth operations.",
            background: 'linear-gradient(122deg, #71CA9B, #58544d)'
        },
    ];

    return (
        <>
        <Box sx={{backgroundColor: '#E8F0EC', padding: {xs: '26px 0px',md: '40px 0px'}, mt: {xs: '40px', sm: '2rem',md:'2rem'}}}>
        <Box>
            <Typography textAlign='center' fontSize='29px' color='#3A3F3C' sx={{fontFamily: "'GeneralSansSemibold', sans-serif", letterSpacing: '-0.5px'}}>Why Choose Anahad?</Typography>
            <Typography color='#3C4959' textAlign='center' sx={{fontFamily: "'GeneralSansRegular', sans-serif", fontSize: {xs: '15px', sm: '18px', md:'18px'}}} >We ensure pharmacies receive only the best <br/> medicines from trusted brands and on time</Typography>
        </Box>
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: {xs: '20px',sm: '20px',md:'4rem'},
                justifyContent: "center",
                padding: {xs: '32px 18px', sm: 4, md:4},
            }}
        >
            {features.map((feature, index) => (
                <Box
                    key={index}
                    sx={{
                        position: "relative",
                        borderRadius: "16px",
                        overflow: "hidden",
                        width: "320px",
                        height: "375px",
                        // backgroundColor: "#ffffff",
                        // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    {/* Background Image */}
                    <Box
                        component="img"
                        src={feature.image}
                        alt={feature.title}
                        sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: '16px',
                            objectFit: {md:"contain"},
                        }}
                    />

                    {/* LinkedIn Icon */}
                    {/* <Box
                        sx={{
                            position: "absolute",
                            top: "16px",
                            right: "16px",
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}
                    >
                        <img
                            src={linkdinIcon}
                            alt="LinkedIn"
                            style={{ width: "35px", height: "35px" }}
                        />
                    </Box> */}

                    {/* Card Content */}
                    <CardContent
                        sx={{
                            position: 'absolute',
                            padding: "16px",
                            height: "20%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            background: feature.background,
                            bottom: '16px',
                            width: '80%',
                            left: '16px',
                            borderRadius: '20px'
                        }}
                    >
                        <Typography
                            // variant="h6"
                            sx={{
                                color: "#fff",
                                marginBottom: "8px",
                                fontSize: "18px",
                                fontFamily: "'GeneralSansMedium', sans-serif"
                            }}
                        >
                            {feature.title}
                        </Typography>
                        <Typography
                            // variant="body2"
                            sx={{
                                color: "#fff",
                                fontSize: "14px",
                                // lineHeight: "1.5",
                                fontFamily: "'GeneralSansRegular', sans-serif"
                            }}
                        >
                            {feature.description}
                        </Typography>
                    </CardContent>
                </Box>
            ))}
        </Box>
        </Box>
        </>
    );
};

export default FeatureCards;
