import React, { useState } from "react";
import Navbar from "../HomeScreen/Navbar";
import ManufacturerPage from "./SpecificBrands";
import Footer from "../HomeScreen/Footer";
import { Box } from "@mui/material";



const ProductPage = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    return (
        <>
            <Box display={{ xs: 'none', sm: 'block', md: 'block' }}>
                <Box sx={{ backgroundColor: '#f8f8f8 ' }}>
                    <Box sx={{ background: 'radial-gradient(circle at top right, #bde8d2, #ffffff70 2%, rgba(255, 255, 255, 0.2) 3%), radial-gradient(circle at bottom left, #f9f9f970, #efebe33d 26%, rgb(241 241 241 / 70%) 14%)' }}>
                        <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                        <ManufacturerPage />
                    </Box>
                </Box>
                <Footer />
            </Box>

            <Box display={{ xs: 'block', sm: 'none', md: 'none' }} sx={{ backgroundColor: '#f8f8f8' }}>
                <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                <Box sx={{  marginTop: isDrawerOpen ? "253px" : "0",transition: "margin-top 0.3s ease"}}>
                    <Box sx={{ background: 'radial-gradient(circle at top left, #ade9c952 , #f6fffa30 3%, rgba(255, 255, 255, 0.5) 45%)' }}>
                        <ManufacturerPage />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ProductPage