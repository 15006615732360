import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Config from "../utils/Config"; 
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null); 
  const [error, setError] = useState(null); 
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const location = useLocation();

  const navigate = useNavigate()

  const getLoginCredentials = async (email, password) => {
    setIsSubmitting(true);
    setError(null);
  
    try {
      console.log("API URL:", Config.Login_Url);
      const response = await axios.post(Config.Login_Url, {
        userName: email,
        password,
      });
  
      console.log("API Response Data:", response.data);
  
      if (response?.data?.status === 200) {
        const { apiToken, apiData } = response.data;
        if (apiToken && apiData) {
          localStorage.setItem("apiToken", apiToken);
          localStorage.setItem("userData", JSON.stringify(apiData));
  
          setUserData({ ...apiData, apiToken });
          return true; 
        } else {
          setError("Missing token or user data in the response.");
          return false; 
        }
      } else {
        setError("Invalid credentials, please try again.");
        return false; 
      }
    } catch (err) {
      console.error("API Error:", err);
      setError(
        err.response
          ? err.response.data.message
          : "An error occurred while logging in. Please try again."
      );
      return false; 
    } finally {
      setIsSubmitting(false);
    }
  };

  const apiToken = localStorage.getItem("apiToken");

  // Logout function
  const logout = () => {
    localStorage.removeItem("apiToken");
    localStorage.removeItem("userData");
    setUserData(null);
    navigate('/login')
  };

  const checkAuth = () => {
    const token = localStorage.getItem('apiToken');
    const unprotectedRoutes = ['/login', '/', '/aboutus', '/contact', '/products', '/trackOrders', '/productdetails'];  // Add unprotected routes here
    const isProductDetailsPage = location.pathname.startsWith('/productdetails');

    if (!token && !unprotectedRoutes.includes(location.pathname) && !isProductDetailsPage) {
      navigate('/login'); // Redirect to login if the user is not logged in and is accessing a protected route
      toast.error("Please log in to go to the dashboard.", {
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          backgroundColor: "#FFEAEA", // Red background for the error toast
          color: "#6A6A6A",
          borderRadius: "8px",
          fontSize: "15px",
          padding: "11px",
          transition: "all 0.3s ease-in-out",
        },
      });
    }
  };
  useEffect(() => {
    checkAuth();
  }, [location]); 

  return (
    <AuthContext.Provider
      value={{
        userData,
        getLoginCredentials,
        logout,
        error,
        isSubmitting, apiToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
