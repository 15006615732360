import React, { useCallback, useContext, useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    LinearProgress,
    Grid,
    IconButton,
    TextField,
    InputAdornment,
    Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import calenderImg from '../images/uis_calender.png'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import searchIcon from '../images/search-icon.png'
import arrowImg from '../images/arrowicon.png'
import OrderStatusProgress from "../Atoms/StepProgressbar";
import ploygenImg from "../images/Polygon.png"
import { Link } from "react-router-dom";
import admin_icon from '../images/person.png'
import settings_icon from '../images/settings.png'
import notification_icon from '../images/notification.png'
import axios from "axios";
import Config from "../utils/Config";
import { AuthContext } from "../Context/AuthContext";
import moment from "moment/moment";
import { debounce } from "lodash";

const getStatusChip = (status) => {
    if (status === "Delivered")
        return <Chip label="Completed" style={{ backgroundColor: "#66BB6A", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
    if (status === "Pending" || status === "Bill Generated" || status === "Preparing Dispatch" || status === "Goods Dispatched")
        return <Chip label="Pending" style={{ backgroundColor: "#CBD5E0", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
    if (status === "Cancelled")
        return <Chip label="Cancelled" style={{ backgroundColor: "#E01E38", color: "#fff", width: '96px', height: '25px', borderRadius: '8px' }} />;
};

const OrdersList = () => {

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [orderData, setOrderData] = useState([]);
    const [fromOpen, setFromOpen] = useState(false);
    const { apiToken } = useContext(AuthContext);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelToken, setCancelToken] = useState(null);
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        debounceSearch(e.target.value);
    };

    const debounceSearch = useCallback(
        debounce((query) => {
            setDebouncedSearchQuery(query);
        }, 300),
        [] // Ensure debounce is not recreated on re-renders
    );

    useEffect(() => {
        // Fetch orders when debouncedSearchQuery changes
        setPage(1); // Reset pagination
        setOrderData([]); // Clear previous results
        setHasMore(true); // Reset pagination
        fetchOrders(1); // Initial fetch
    }, [debouncedSearchQuery]);

    const handleDateChange = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.format("YYYY-MM-DD");
            setSelectedDate(formattedDate);
            setPage(1);
            setOrderData([]);
            setHasMore(true); // Reset pagination
        }
    };

    const fetchOrders = async (newPage) => {
        if (isLoading || !hasMore) return; // Prevent duplicate calls
        setIsLoading(true);

        // Cancel previous request if any
        if (cancelToken) {
            cancelToken.cancel("Operation canceled due to new request.");
        }

        // Create a new cancel token for the current request
        const newCancelToken = axios.CancelToken.source();
        setCancelToken(newCancelToken);

        try {
            const response = await axios.post(
                Config?.Get_Orders,
                {
                    search: searchQuery, // Use searchQuery (debounced value)
                    page: newPage,
                    date: selectedDate
                },
                {
                    headers: {
                        Authorization: `Bearer ${apiToken}`,
                    },
                    cancelToken: newCancelToken.token, // Use cancel token
                }
            );

            if (response?.status === 200) {
                const newOrders = response?.data?.apiData || [];
                setOrderData((prev) => [...prev, ...newOrders]);
                setHasMore(newOrders.length > 0);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("Request canceled", error.message);
            } else {
                console.error("Error in fetching orders:", error);
                setHasMore(false);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const filteredOrders = orderData.filter(order => {
        return (
            order.InvNo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            order.CName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });
    useEffect(() => {
        fetchOrders(page);
    }, [page, selectedDate, searchQuery]);

    const handleScroll = debounce((e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollHeight - scrollTop <= clientHeight * 1.2 && hasMore && !isLoading) {
            setPage((prev) => prev + 1);
        }
    }, 100); // Throttle scroll event


    const formatCName = (cName) => {
        if (!cName) return "";
        // Remove extra characters (like numbers and parentheses)
        const cleanedName = cName.replace(/\(.*?\)|\d+/g, "").trim();
        // Capitalize the first letter of each word
        return cleanedName
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const formatAddress = (address) => {
        if (!address) return "";
        // Convert to lowercase and capitalize the first letter of each word
        return address
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const handleSortOrderValue = () => {
        const sortedOrders = [...orderData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.Balance - b.Balance; // Sort low to high
            } else {
                return b.Balance - a.Balance; // Sort high to low
            }
        });
        setOrderData(sortedOrders); // Update sorted data
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order
    };


    return (
        <Box>
            {/* Top Bar */}
            <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
                <Box pt='16px'>
                    <Box flexDirection='row' display='flex' alignItems='center' gap='3px'>
                        <Typography variant="h6" sx={{ color: '#a0aec0', fontSize: '14px' }}>
                            Pages
                        </Typography>
                        <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>/</Typography>
                        <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>  Orders</Typography>
                    </Box>
                    <Typography mt={1} fontSize='14px' fontWeight='bold' color="#2D3748">Orders</Typography>
                </Box>
                <Box p={'16px'} display='flex' gap='20px'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display="flex" alignItems="center" gap='16px'>
                            <DatePicker
                                value={dayjs(selectedDate)}
                                onChange={handleDateChange}
                                open={fromOpen}
                                onOpen={() => setFromOpen(true)}
                                onClose={() => setFromOpen(false)}
                                slots={{
                                    textField: TextField,
                                }}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        InputProps: {
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setFromOpen(true);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Box
                                                        component="img"
                                                        src={calenderImg}
                                                        alt="calendar_icon"
                                                        sx={{
                                                            width: "20px",
                                                            height: "20px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        },
                                        sx: {
                                            color: "white",
                                            backgroundColor: "#71CA9B",
                                            borderRadius: "15px",
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "15px",
                                                color: "white",
                                                height: "39px",
                                                width: "161px",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                            },
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                border: "none",
                                            },
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </LocalizationProvider>

                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#ffffff",
                            padding: "5px 10px",
                            borderRadius: "15px",
                            border: '1px solid #E2E8F0',
                            width: '250px'
                        }}>
                        <Box component={'img'} src={searchIcon} />
                        <input
                            placeholder="Search Pharmacy"
                            value={searchQuery} // Bind search query to input
                            onChange={handleSearchChange} // Handle input change
                            style={{
                                border: "none",
                                background: "transparent",
                                marginLeft: "10px",
                                outline: "none",
                                color: 'gray.400'
                            }}
                        />
                    </Box>
                    <Box display='flex' gap='20px' alignItems='center'>
                        <Stack direction='row' alignItems={'baseline'} gap='3px'>
                            <Box component={'img'} src={admin_icon} alt='user' sx={{ width: '12px', height: '12px' }} />
                            <Typography fontSize='12px' fontWeight='bold' color='#718096'>Admin</Typography>
                        </Stack>
                        <Box component={'img'} src={settings_icon} alt='settings' sx={{ width: '12px', height: '12px' }} />
                        <Box component={'img'} src={notification_icon} alt='notification' sx={{ width: '12px', height: '12px' }} />
                    </Box>
                </Box>
            </Stack>

            {/* Main Content */}
            <Box sx={{ marginTop: "20px", backgroundColor: '#ffffff', p: '24px', borderRadius: '15px' }}>
                <Typography variant="h5" sx={{ marginBottom: "10px", fontWeight: 'bold', fontSize: '18px', color: '#2D3748', pl: '15px' }}>
                    Pharmacy Orders
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tableheadcell" sx={{ width: '8%' }}>Invoice Number</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: "18%" }}>Pharmacy Name</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: '9%' }}>Order Date</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: '8%' }}>Status</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: '8%' }}>Completion</TableCell>
                                <TableCell className="tableheadcell" sx={{ width: "9%" }}>Order Value <span onClick={handleSortOrderValue}
                                    style={{ cursor: 'pointer', userSelect: 'none' }}><Box component={'img'} src={ploygenImg} alt="filter-arrow" ml='3px' sx={{
                                        transform: sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                        transition: 'transform 0.2s'
                                    }} /></span></TableCell>
                                <TableCell className="tableheadcell" sx={{ width: '3%' }}></TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Box onScroll={handleScroll} sx={{
                        maxHeight: '410px', overflowY: 'auto', '&::-webkit-scrollbar': {
                            width: '8px',
                            height: '12px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#d9d9d9',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#4A5568',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#fff',
                        },
                    }}>
                        <Table>
                            <TableBody>
                                {filteredOrders.map((row, index) => (
                                    <Link key={index} to={`/orderdetails/${encodeURIComponent(row.InvNo)}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                        <TableRow sx={{ "&:hover": { backgroundColor: "#f9f9f9" } }}>
                                            <TableCell className="tablerowcell" sx={{ width: '10%' }}>{row.InvNo}</TableCell>
                                            <TableCell className="tablerowcell" sx={{ width: "22%" }}>
                                                <Box width={'85%'}>
                                                    {formatCName(row.CName)}
                                                    <Typography fontSize='14px' sx={{ color: '#718096' }} variant="body2" color="textSecondary">
                                                        {formatAddress(row.Add_1)}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell className="tablerowcell" sx={{ width: '9%' }}>{moment(row.OrderDate).format('DD/MM/YY')}</TableCell>
                                            <TableCell className="tablerowcell" sx={{ width: '10%' }} >{getStatusChip(row.Status)}</TableCell>
                                            <TableCell className="tablerowcell" sx={{ width: '9%' }}>
                                                {/* <Box >
                                            <Typography variant="body2">{`${row.completion}%`}</Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={row.completion}
                                                color="success"
                                                sx={{ flexGrow: 1, marginRight: 1, height: "8px", borderRadius: "4px" }}
                                            />
                                        </Box> */}
                                                <OrderStatusProgress status={row?.Status} />
                                            </TableCell>
                                            <TableCell className="tablerowcell" sx={{ width: "9%" }}>{row.Balance}</TableCell>
                                            <TableCell sx={{ width: '3%' }}>

                                                <Box component={'img'} src={arrowImg} alt="rightArrow" />
                                            </TableCell>
                                        </TableRow>
                                    </Link>
                                ))}

                                {isLoading && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            Loading...
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!hasMore && !isLoading && orderData.length > 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No more data to load.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default OrdersList;
