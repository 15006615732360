// Dashboard.js
import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DashboardOrderStats from './DashboardOrderStats';
import OrderStatusChart from '../components/Charts/OrderStatusChart';
import SalesOverviewChart from '../components/Charts/SalesOverviewChart';
import Sidebar from '../components/Sidebar';
import DashboardHeader from '../components/DashboardHeader';
import dayjs from 'dayjs';

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
//   textAlign: 'center',
//   color: theme?.palette?.text?.secondary || '#000', // Default color if theme is undefined
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   flexDirection: 'column',
//   borderRadius: theme.spacing(1),
//   boxShadow: theme.shadows[3],
// }));

const Dashboard = () => {
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());

  // Callback to handle date changes from the DashboardHeader
  const handleDateChange = (from, to) => {
    setFromDate(from);
    setToDate(to);
  };
  return (
    <>
    <Box sx={{backgroundColor: '#f9f9f9'}}>
      <Box style={{ display: 'flex', padding: '0px 20px 20px 10px' }}>
        <Sidebar />
        <Box sx={{ px: '16px' }}>
          <DashboardHeader onDateChange={handleDateChange} />
          <DashboardOrderStats fromDate={fromDate} toDate={toDate} />
          <Box paddingTop='20px' justifyContent='space-between' display='flex' width={'100%'}>
            <Box width='44%' bgcolor='white' padding='20px 18px' borderRadius='15px' boxShadow='1px 2px 5px #f2f2f2'>
              <Box><OrderStatusChart fromDate={fromDate} toDate={toDate}/></Box>
            </Box>
            <Box width='51%' bgcolor='white' borderRadius='15px' boxShadow='1px 2px 5px #f2f2f2'><SalesOverviewChart /></Box>
          </Box>
        </Box>
      </Box>
      </Box>
    </>
  );
};

export default Dashboard;
