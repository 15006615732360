import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";


const MapWithDirections = ({ orderNo, driver_id, route_id, route }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDoFGusGnGwn-WjQnvH2jIDeAdtyXVxhNU", // Replace with your API key
  });

  const mapRef = useRef(null); // Reference for the map instance

  const origin = useMemo(() => {
    if (route.start_latitude && route.start_longitude) {
      return { lat: parseFloat(route.start_latitude), lng: parseFloat(route.start_longitude) };
    }
    return null;
  }, [route]);

  const destination = useMemo(() => {
    if (route.end_latitude && route.end_longitude) {
      return { lat: parseFloat(route.end_latitude), lng: parseFloat(route.end_longitude) };
    }
    return null;
  }, [route]);

  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [routeStops, setRouteStops] = useState([]);
  const [routePolyline, setRoutePolyline] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (route && route.route_stops) {
      setRouteStops(route.route_stops);
    }
  }, [route]);

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    borderRadius: "12px",
  };

  const center = useMemo(() => {
    if (origin) {
      return origin;
    }
    return { lat: 19.076, lng: 72.8777 };
  }, [origin]);

  const getDirections = async (origin, destination, routeStops) => {
    const directionsService = new window.google.maps.DirectionsService();

    // Split routeStops into chunks of 25
    const chunkSize = 25;
    const chunks = [];
    for (let i = 0; i < routeStops.length; i += chunkSize) {
      chunks.push(routeStops.slice(i, i + chunkSize));
    }

    let fullRoute = [];
    for (let i = 0; i < chunks.length; i++) {
      const waypoints = chunks[i].map((stop) => ({
        location: { lat: parseFloat(stop.latitude), lng: parseFloat(stop.longitude) },
        stopover: true,
      }));

      const directionsRequest = {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      try {
        const result = await new Promise((resolve, reject) => {
          directionsService.route(directionsRequest, (result, status) => {
            if (status === "OK") {
              resolve(result);
            } else {
              reject(new Error("Directions request failed due to " + status));
            }
          });
        });

        fullRoute = [...fullRoute, ...result.routes[0].overview_path];

        if (i === chunks.length - 1) {
          setDirectionsResponse(result);
        }
      } catch (error) {
        console.error(error.message);
      }
    }

    // Set the final polyline after all chunks are processed
    const routePolyline = fullRoute.map((point) => ({
      lat: point.lat(),
      lng: point.lng(),
    }));
    setRoutePolyline(routePolyline);
  };

  useEffect(() => {
    if (!isLoaded || !origin || !destination) return;

    getDirections(origin, destination, routeStops);
  }, [isLoaded, origin, destination, routeStops]);

  const handleMarkerClick = (marker, stopAddress) => {
    setSelectedMarker(marker);
    setAddress(stopAddress);

    if (mapRef.current) {
      mapRef.current.panTo(marker);
      mapRef.current.setZoom(16);
    }
  };

  const reverseGeocode = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address);
      } else {
        console.error("Geocode was not successful for the following reason:", status);
      }
    });
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        height: "88%",
        borderRadius: "12px",
        border: "2px solid white",
        overflow: "hidden",
        boxShadow: "0px 2px 35px 4px #e3e3f3",
      }}
    >
      <div style={{ flex: 1, position: "relative" }}>
      <GoogleMap
  mapContainerStyle={mapContainerStyle}
  center={center}
  zoom={14}
  options={{
    disableDefaultUI: true,
  }}
  onLoad={(map) => {
    mapRef.current = map;
  }}
>
  {/* Conditional rendering of markers */}
  {origin && <Marker position={origin} title="Start" label="A" />}
  
  {/* Only show destination marker if it's different from the origin */}
  {destination && !(origin.lat === destination.lat && origin.lng === destination.lng) && (
    <Marker position={destination} title="End" label="B" />
  )}

  {/* Only display markers for stops if it's not the origin or destination */}
  {routeStops.map((stop, index) => {
    const stopPosition = { lat: parseFloat(stop.latitude), lng: parseFloat(stop.longitude) };
    return (
      !(
        (origin && stopPosition.lat === origin.lat && stopPosition.lng === origin.lng) ||
        (destination && stopPosition.lat === destination.lat && stopPosition.lng === destination.lng)
      ) && (
        <Marker
          key={index}
          position={stopPosition}
          title={stop.address}
          label={{
            text: `Stop ${index + 1}`,
            fontSize: "11px", // Custom font size
            color: "black", // Text color
          }}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: "#D64141", 
            fillOpacity: 1,
            strokeWeight: 2,
            strokeColor: "white",
          }}
          onClick={() => handleMarkerClick(stop, stop.address)}
          onMouseOver={() => reverseGeocode(stop.latitude, stop.longitude)}
        />
      )
    );
  })}

  {/* DirectionsRenderer with custom polyline color */}
  {directionsResponse && (
    <DirectionsRenderer
      directions={directionsResponse}
      options={{
        polylineOptions: {
          strokeColor: "#1F9C23",  // Custom color for the polyline
          strokeOpacity: 0.8,
          strokeWeight: 6,
        },
      }}
    />
  )}
</GoogleMap>


      </div>
    </div>
  );
};

export default MapWithDirections;