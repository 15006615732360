import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../HomeScreen/Navbar";
import GetInTouch from "./GetInTouch";
import FAQSection from "./FAQ";
import Footer from "../HomeScreen/Footer";

const Contact = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    // Hook for media queries
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // This is for xs or below (mobile)
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm')); // This is for sm or above (tablet and larger)

    return (
        <>
            {/* Desktop View (for sm and larger screens) */}
            {isDesktop && (
                <Box sx={{ backgroundColor: '#f8f8f8 ' }}>
                    <Box sx={{ background: 'radial-gradient(circle at top right, #b5edd0, #d4efe170 23%, rgba(255, 255, 255, 0.2) 28%), radial-gradient(circle at bottom left, #f9f9f970, #efebe33d 26%, rgb(241 241 241 / 50%) 28%)' }}>
                        <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                        <GetInTouch />
                    </Box>
                    <FAQSection />
                    <Footer />
                </Box>
            )}

            {/* Mobile View (for xs screens) */}
            {isMobile && (
                <Box sx={{ backgroundColor: '#f8f8f8' }}>
                    <Navbar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
                    <Box sx={{ marginTop: isDrawerOpen ? "253px" : "0", transition: "margin-top 0.3s ease" }}>
                        <Box sx={{ background: 'radial-gradient(circle at top left, #ade9c952, #f6fffa30 5%, rgba(255, 255, 255, 0.5) 45%)' }}>
                            <GetInTouch />
                            <FAQSection />
                            <Footer />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default Contact;
