const BASE_URL = `${process.env.REACT_APP_BASE_URL}`

// export const ZEO_API = `${process.env.REACT_APP_ZEO_API}`;
// export const ZEO_API_KEY = `${process.env.REACT_APP_ZEO_API_KEY}`
// export const GOOGLE_API_KEY = `${process.env.REACT_APP_GOOGLE_API_KEY}`

const Config = {
    Login_Url:  `${BASE_URL}login`,
    Dashboard_details: `${BASE_URL}dashboard-details`,
    Sales_Overview: `${BASE_URL}sales_overview`,
    Get_Orders: `${BASE_URL}getorders`,
    Get_Order_Details: `${BASE_URL}getOrderDetails`,
    Get_Pharmacies_list: `${BASE_URL}getPharmacies`,
    Get_Order_Status_Graph: `${BASE_URL}dashboard_graph`,
    Get_Route_Info: `${BASE_URL}routeInfo`,
    Get_Product_Manufecturers: `${BASE_URL}manufecturers`,
    Get_Products: `${BASE_URL}products` ,
    Get_Appointment: `${BASE_URL}contact`,
    Get_Pharmacy_details: `${BASE_URL}pharmacyDetails`,
    Get_Pharmacy_sales: `${BASE_URL}pharmacy-sales`,
    Get_one_daySales: `${BASE_URL}oneDay_sales`,
    Get_inquiry: `${BASE_URL}inquiry`,
    Logout: `${BASE_URL}logout`
}

export default Config;