import React, { useContext, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
  Grid,
  InputAdornment,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import arrowDown from "../../images/website-images/chevron-down.svg"
import { AuthContext } from "../../Context/AuthContext";
import Config from "../../utils/Config";
import axios from "axios";
import { CustomToast } from "../../Atoms/CustomToast";
import { isValidEmail, isValidPhoneNumber } from "../../utils/ValidationUtils";

export default function AppointmentForm() {
  const inputStyles = {
    backgroundColor: "white",
    marginTop: "6px",
    marginBottom: "8px",
    borderRadius: "15px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D0D5DD",
      },
      "&:hover fieldset": {
        borderColor: "#E2E8F0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E2E8F0",
      },
    },
  };

  const labelStyles = {
    fontFamily: "'GeneralSansMedium', sans-serif",
    fontSize: "15px",
    color: "#3C4959",
  };


  const [fromDate, setFromDate] = useState(dayjs());
  const [fromOpen, setFromOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const { apiToken } = useContext(AuthContext);
    const { addToast } = CustomToast();

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    };

    const handleDateChange = (newValue) => {
      setFromDate(newValue);
      setFormErrors((prev) => ({
        ...prev,
        date: "",
      }));
    };

    const validateForm = () => {
      const errors = {};
      if (!formData.firstName) errors.firstName = "First name is required.";
      if (!formData.lastName) errors.lastName = "Last name is required.";
      if (!formData.email) {
        errors.email = "Email is required.";
      } else if (!isValidEmail(formData.email)) {
        errors.email = "Invalid email format.";
      }
  
      if (!formData.phoneNumber) {
        errors.phoneNumber = "Phone number is required.";
      } else if (!isValidPhoneNumber(formData.phoneNumber)) {
        errors.phoneNumber =
          "Phone number should be valid.";
      }
  
      if (!fromDate) errors.date = "Date is required.";
      return errors;
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
  
      const data = {
        fName: formData.firstName,
        lName: formData.lastName,
        pNumber: formData.phoneNumber,
        email: formData.email,
        Message: formData.message,
        date: fromDate.format("YYYY-MM-DD HH:mm:ss"),
      };
  
      try {
        const response = await axios.post(Config?.Get_Appointment, data, {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        });
  
        if (response?.status === 200) {
          addToast({
            message: "Appointment booked successfully.",
            type: "success",
          });
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
          });
          setFromDate(dayjs());
          setFormErrors({});
        }
      } catch (error) {
        console.error("Error in API request:", error);
        alert("Failed to book appointment.");
      }
    };


  return (
    <Box
      style={{
        backgroundColor: "#fff",
        borderRadius: "16px",
        boxShadow: "rgb(194 194 194 / 10%) 0px 4px 2px",
      }}
      sx={{ padding: { xs: "2rem 1rem", sm: '2rem 1.5rem', md: "3rem 2rem" } }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          {/* First Name */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <label style={labelStyles}>First Name</label>
              <TextField placeholder="First name" size="small" sx={{
                ...inputStyles,
                '& .MuiInputBase-input::placeholder': {
                  fontFamily: "'GeneralSansRegular', sans-serif",
                  fontSize: '14px', color: '#3C4959', opacity: 0.7
                },
              }} name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                error={!!formErrors.firstName}
                helperText={formErrors.firstName}
              />
            </FormControl>
          </Grid>

          {/* Last Name */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <label style={labelStyles}>Last Name</label>
              <TextField placeholder="Last name" size="small" sx={{
                ...inputStyles,
                '& .MuiInputBase-input::placeholder': {
                  fontFamily: "'GeneralSansRegular', sans-serif",
                  fontSize: '14px', color: '#3C4959', opacity: 0.7
                },
              }} name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                error={!!formErrors.lastName}
                helperText={formErrors.lastName}
              />
            </FormControl>
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label style={labelStyles}>Email</label>
              <TextField type="email"
                placeholder="you@company.com"
                size="small"
                sx={{
                  ...inputStyles,
                  '& .MuiInputBase-input::placeholder': {
                    fontFamily: "'GeneralSansRegular', sans-serif",
                    fontSize: '14px', color: '#3C4959', opacity: 0.7
                  },
                }} name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </FormControl>
          </Grid>


          {/* Phone Number */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <label style={labelStyles}>Phone Number</label>
              <TextField type="number"
                placeholder="+91 889 000-0000"
                size="small"
                sx={{
                  ...inputStyles,
                  '& .MuiInputBase-input::placeholder': {
                    fontFamily: "'GeneralSansRegular', sans-serif",
                    fontSize: '14px', color: '#3C4959', opacity: 0.7
                  }
                }} name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!formErrors.phoneNumber}
                helperText={formErrors.phoneNumber}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        defaultValue="IN"
                        variant="standard"
                        disableUnderline
                        IconComponent={() => (
                          <Box
                            component="img"
                            src={arrowDown}  // Your custom icon image
                            alt="arrow_icon"
                            sx={{
                              width: "18px",
                              height: "18px",
                              objectFit: "contain",
                            }}
                          />
                        )}
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          "& .MuiSelect-select": {
                            paddingRight: '3px !important'
                          },
                        }}
                      >
                        <MenuItem value="IN">IN</MenuItem>
                        <MenuItem value="US">US</MenuItem>
                        <MenuItem value="UK">UK</MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Typography style={{ fontFamily: "'GeneralSansMedium', sans-serif", fontSize: '14px', color: '#3C4959', opacity: 0.7 }}>
                Select Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box display="flex" alignItems="center" gap="16px">
                  <DatePicker
                    value={fromDate}
                    onChange={handleDateChange}
                    open={fromOpen}
                    onOpen={() => setFromOpen(true)}
                    onClose={() => setFromOpen(false)}
                    slots={{ textField: TextField }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={(e) => {
                                e.stopPropagation();
                                setFromOpen(true); // Open the date picker when the icon is clicked
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <Box
                                component="img"
                                src={arrowDown}
                                alt="calendar_icon"
                                sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                              />
                            </InputAdornment>
                          ),
                        },
                        sx: {
                          ...inputStyles,
                          '& .MuiInputBase-input::placeholder': {
                            fontFamily: "'GeneralSansRegular', sans-serif",
                            fontSize: '14px', color: '#3C4959', opacity: 0.7
                          },
                          // backgroundColor: "#71CA9B",
                          // borderRadius: "15px",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "15px",
                            height: "40px",

                            fontFamily: "'GeneralSansRegular', sans-serif",
                            fontSize: '15px', color: '#667085',
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#E2E8F0",
                            },
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            // border: "none",  
                            border: '2px solid #E2E8F0',
                            borderRadius: '4px'
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </LocalizationProvider>
              {formErrors.date && (
                <Typography color="error" fontSize="12px" mt={1}>
                  {formErrors.date}
                </Typography>
              )}
            </FormControl>
          </Grid>

          {/* Message Box */}
          <Grid item xs={12} >
            <FormControl fullWidth>
              <label style={labelStyles}>Message</label>
              <TextField
                // placeholder="Write a message"
                name="message"
                multiline
                rows={4}
                sx={inputStyles}
                value={formData.message}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              // variant="contained"
              // fullWidth
              type="submit"
              style={{
                backgroundColor: "#60BC8C",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                padding: "8px 3rem",
                marginTop: '10px',
                borderRadius: '10px'
              }}
            >
              Book an appointment
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
