import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Progressbar from "../../Atoms/LinearProgressBar";
import cartimg from "../../images/cart.png"
import docimg from "../../images/money-icon.png"
import rocketimg from "../../images/rocket.png"
import axios from "axios";
import Config from "../../utils/Config";
import { AuthContext } from "../../Context/AuthContext";

const OrderStatusChart = ({ fromDate, toDate }) => {

    const [orderstatus, setOrderStatus] = useState({ data: [], max: 0 });
    const { apiToken } = useContext(AuthContext)

    // const data = [
    //     { name: 'Bill Generation', value: 200 },
    //     { name: 'Preparing Dispatch', value: 500 },
    //     { name: 'Goods Dispatch', value: 300 },
    //     { name: 'Out for Delivery', value: 200 },
    //     { name: 'Order Delivered', value: 180 },
    // ];


    const GetOrderStatusChart = async () => {
        try {
            const response = await axios.post(
                Config?.Get_Order_Status_Graph,
                { startDate: fromDate, endDate: toDate },
                { headers: { Authorization: `Bearer ${apiToken}` } }
            );

            if (response?.status === 200) {
                const data = response?.data?.apiData[0];
                const formattedData = [
                    { name: "Bill Generation", value: data.BillGeneratedCount },
                    { name: "Preparing Dispatch", value: data.PreparingDispatchCount },
                    { name: "Goods Dispatch", value: data.DispatchedCount },
                    { name: "Out for Delivery", value: data.OutForDeliveryCount },
                    { name: "Order Delivered", value: data.DeliveredCount },
                ];

                const maxValue = Math.max(...formattedData.map((item) => item.value)) || 0;

                // Debugging log
                console.log("Formatted Data:", formattedData);
                console.log("Max Value:", maxValue);

                setOrderStatus({ data: formattedData, max: maxValue });
            }
        } catch (error) {
            console.error("Error fetching API data:", error);
            setOrderStatus({ data: [], max: 0 }); // Fallback to prevent crashes
        }
    };

    useEffect(() => {
        GetOrderStatusChart();

        const intervalId = setInterval(() => {
            GetOrderStatusChart();
        }, 300000);

        return () => clearInterval(intervalId);
    }, [fromDate, toDate]);

    const CustomTick = ({ x, y, payload }) => {
        const label = payload.value;
        const lines = label === "Out for Delivery" ? ["Out for", "Delivery"] : label.split(" ");

        return (
            <text x={x} y={y + 10} fill="#a0aec0" fontFamily="Helvetica" fontWeight={600} fontSize={12} textAnchor="middle">
                {lines.map((line, index) => (
                    <tspan x={x} dy={index === 0 ? 0 : 14} key={index}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };
    return (
        <>
            <div
                style={{
                    width: "470px",
                    height: '222px',
                    background: "linear-gradient(105deg, rgb(33 102 62), #1B3B33)",
                    borderRadius: "20px",
                    padding: "10px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >

                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={orderstatus.data}
                        margin={{ top: 20, right: 20, left: 0, bottom: 0 }}
                    >
                        <XAxis
                            dataKey="name"
                            tick={<CustomTick />}
                            interval={0}
                            height={40}
                            tickLine={false}
                        />
                        <YAxis
                            tick={{ fill: "#E6E6E6", fontSize: 12 }}
                            interval={0}
                            domain={orderstatus.max > 0 ? [undefined, Math.ceil(orderstatus.max)] : [0, 1]} // Dynamically set lower bound
                            tickFormatter={(tick) => Math.round(tick)}
                            ticks={
                                orderstatus.max > 0
                                    ? [
                                        0,
                                        Math.round(orderstatus.max / 4),
                                        Math.round(orderstatus.max / 2),
                                        Math.round(orderstatus.max * 0.75),
                                        Math.round(orderstatus.max),
                                    ]
                                    : [0]
                            } // Skip 0 when data exists
                        />
                        <Bar dataKey="value" fill="#ffffff" radius={[10, 10, 0, 0]} barSize={60} />
                    </BarChart>
                </ResponsiveContainer>

            </div>
            <Box>
                <Typography variant="h4" sx={{ color: '#2D3748', fontSize: '18px', fontWeight: 'bold', mt: '24px', mb: 1 }}>Order Status Breakdown</Typography>
                <Stack direction='row' gap='6px' mb='2rem'>
                    <Typography variant="body1" sx={{ color: '#66bb6a', fontSize: '14px' }}>(+23)</Typography>
                    <Typography variant="body1" sx={{ color: 'grey.500', fontSize: '14px' }}> then last week</Typography>
                </Stack>

                <Stack direction='row' width='525px' justifyContent='space-between'>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='7px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Bill <br /> Generation</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'> {orderstatus.data[0]?.value || 0}</Typography>
                        <Progressbar value={
                            (orderstatus.data[1]?.value / (orderstatus.max || 1)) * 100
                        } />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='7px' marginBottom='8px'>

                            <Box component={'img'} src={rocketimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />

                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Preparing <br /> Dispatch</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>{orderstatus.data[1]?.value || 0}</Typography>
                        <Progressbar
                            value={
                                (orderstatus.data[1]?.value / (orderstatus.max || 1)) * 100
                            }
                        />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='7px' marginBottom='8px'>
                            <Box component={'img'} src={cartimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Goods <br /> Dispatch</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>{orderstatus.data[2]?.value || 0}</Typography>
                        <Progressbar
                            value={
                                (orderstatus.data[2]?.value / (orderstatus.max || 1)) * 100
                            }
                        />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='7px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Out for <br /> Delivery</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'> {orderstatus.data[3]?.value || 0}</Typography>
                        <Progressbar
                            value={
                                (orderstatus.data[3]?.value / (orderstatus.max || 1)) * 100
                            }
                        />
                    </Box>
                    <Box>
                        <Stack direction='row' alignItems='center' gap='7px' marginBottom='8px'>
                            <Box component={'img'} src={docimg} padding='10px 12px' borderRadius='6px' bgcolor='#71CA9B' />
                            <Typography sx={{ color: '#A0AEC0', fontSize: '12px', fontWeight: 'bold' }}>Order <br /> Delivered</Typography>
                        </Stack>
                        <Typography marginBottom='8px' color='#2D3748' fontWeight='bold'>  {orderstatus.data[4]?.value || 0}</Typography>
                        <Progressbar value={
                            (orderstatus.data[4]?.value / (orderstatus.max || 1)) * 100
                        } />
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default OrderStatusChart