import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Typography, Grid, Card, CardContent, CardMedia, Stack } from "@mui/material";
import brandImg1 from "../../images/website-images/torrent.png";
import brandImg2 from "../../images/website-images/wallace.png";
import brandImg3 from "../../images/website-images/ipca.png";
import brandImg4 from "../../images/website-images/cipla.png";
import brandImg5 from "../../images/website-images/mankind.png";
import brandImg6 from "../../images/website-images/pharmaCare.png";
import brandImg7 from "../../images/website-images/abbott.png";
import brandImg8 from "../../images/website-images/viatris-preview.png";
import brandImg9 from "../../images/website-images/RB-preview.png";
import brandImg10 from "../../images/website-images/indoco-preview.png";
import verticle_line from "../../images/website-images/verticle_line.svg";
import horizontal_line from "../../images/website-images/Line65.svg"
import verticle_line_responsive from "../../images/website-images/verticle_line_responsive.svg"
import axios from "axios";
import Config from "../../utils/Config";
import { AuthContext } from "../../Context/AuthContext";
import { Link } from "react-router-dom";

const ManufacturerPage = () => {
  const { apiToken } = useContext(AuthContext)
  const [mfgCompany, setMfgCompany] = useState([]);
  const hasFetched = useRef(false);
  const imageMapping = {
    "Torrent Pharma Ltd": brandImg1,
    "Wallace": brandImg2,
    "Ipca": brandImg3,
    "Cipla": brandImg4,
    "Mankind": brandImg5,
    "PharmaCare": brandImg6,
    "Abbott": brandImg7,
    "Viatris": brandImg8,
    "RB": brandImg9,
    "Indoco": brandImg10,
  };

  const GetProductManufecturer = async () => {
    try {
      const response = await axios.get(Config?.Get_Product_Manufecturers, {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      });
      if (response?.status === 200) {
        setMfgCompany(response?.data?.apiData);
        console.log(response?.data?.apiData, 'mfgName')
      }
    } catch (error) {
      console.error("Error in fetching API response.");
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true; // Mark it as fetched
      GetProductManufecturer();
    }
  }, [apiToken]);

  // Transform API data into grouped structure
  const transformedData = mfgCompany.reduce((acc, item) => {
    const name = item["Parent Manufacturer"];
    const letter = name.charAt(0).toUpperCase();
    const image = imageMapping[name] || brandImg4; // Default image if not found
    const manufacturer = { name, img: image, width: "75px", width_s: "60px" };

    const group = acc.find((group) => group.letter === letter);
    if (group) {
      group.manufacturers.push(manufacturer);
    } else {
      acc.push({ letter, manufacturers: [manufacturer] });
    }
    return acc;
  }, []).sort((a, b) => a.letter.localeCompare(b.letter));
  const data = [
    {
      letter: "A",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
      ],
    },
    {
      letter: "B",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
      ],
    },
    {
      letter: "C",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
      ],
    },
    {
      letter: "D",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
      ],
    },
    {
      letter: "E",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
      ],
    },
    {
      letter: "F",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
      ],
    },
    {
      letter: "G",
      manufacturers: [
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg4, width: "100px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg5, width: "89px", width_s: '45px' },
        { name: "Torrent Pharma Ltd", img: brandImg10, width: "90px", width_s: '50px' },
        { name: "Torrent Pharma Ltd", img: brandImg1, width: "110px", width_s: '60px' },
        { name: "Torrent Pharma Ltd", img: brandImg2, width: "105px", width_s: '55px' },
        { name: "Torrent Pharma Ltd", img: brandImg3, width: "85px", width_s: '50px' },
      ],
    },
  ];


  return (
    <Box sx={{ padding: { xs: 2, sm: 4, md: 4 } }}>
      <Box sx={{
        width: { xs: '100%', sm: '86%', md: "86%" },
        margin: "auto",
      }}>
        <Box
          sx={{
            padding: { xs: '20px 0px', sm: '28px 16px', md: "28px 16px" },
            borderRadius: { xs: '20px', sm: 1, md: 1 },
            my: '2rem',
            background: "linear-gradient(45deg, #53A57A, #518369)",
          }}>
          <Typography
            variant="h5"
            sx={{
              color: "white",
              fontSize: { xs: '23px', sm: '28px', md: "28px" },
              textAlign: "center",
              fontFamily: "'GeneralSansSemibold', sans-serif",
            }}
          >
            Manufacturer name
          </Typography>
        </Box>
        {transformedData.map((section, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="99%"
              margin="auto"
              marginBottom="26px !important"
            >
              <Typography
                variant="h6"
                sx={{ fontFamily: "'GeneralSansRegular', sans-serif", color: "#606362", fontSize: { xs: '16px', sm: '20px', md: '22px' } }}
              >
                {section.letter}
              </Typography>
              <Box
                component={"img"}
                src={verticle_line}
                alt="verticle_line"
                width="97.5%"
                display={{ xs: "none", sm: "block", md: "block" }}
              />
              <Box
                component={"img"}
                src={verticle_line_responsive}
                alt="verticle_line"
                width="93%"
                display={{ xs: "block", sm: "none", md: "none" }}
              />
            </Stack>
            <Grid container width="100%" margin="auto" gap={{ xs: "12px", md: "20px" }}>
              {section.manufacturers.map((manufacturer, idx) => (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  key={idx}
                  sx={{
                    maxWidth: { xs: "47%", md: "32.1% !important" },
                    flexBasis: { xs: "47%", md: "32.1% !important" },
                    flexGrow: 0,
                  }}
                >
                  <Link to={`/productdetails/${manufacturer?.name}`} style={{color: 'inherit', textDecoration: 'none'}}>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: { xs: "5px 5px", md: "22px 35px" },
                    borderRadius: "15px",
                    height: "53px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                  }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      sx={{
                        position: "relative",
                        "&::before": {
                          content: '""',
                          width: "1px", // Line width
                          height: {xs:"41px",sm: '60px',md:"64px"}, // Fix the height to 100% of the container
                          backgroundColor: "#ccc",
                          position: "absolute",
                          top: "50%", // Vertically center the line
                          left: "40%",
                          transform: "translateY(-50%)", // Adjust to center the line vertically
                        },
                      }}
                    >
                      <Box
                        component="img"
                        src={manufacturer.img}
                        alt={manufacturer.name}
                        sx={{
                          width: { xs: manufacturer?.width_s, sm: manufacturer?.width_s, md: manufacturer.width },
                          visibility: 'hidden'
                        }}
                      />
                      <Typography
                        sx={{
                          textTransform: "uppercase",
                          fontFamily: "Poppins",
                          fontSize: { xs: '11px', md: '16px' },
                          paddingBottom: 0,
                          width: { xs: '50%', md: "50%" },
                          whiteSpace: "normal", // Allow the text to wrap
                          overflow: "hidden", // Hide overflowed text
                          textOverflow: "ellipsis", // Add ellipsis for overflow text
                          display: "-webkit-box", // Required for multi-line truncation
                          WebkitBoxOrient: "vertical", // Needed for multi-line truncation
                          WebkitLineClamp: 2, // Limit the text to 2 lines
                        }}>
                        {manufacturer.name}
                      </Typography>
                    </Stack>
                  </Box>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ManufacturerPage;
