import React, { useEffect, useState } from "react";
import { Box, List, ListItem, Typography, IconButton, styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import anahadsidelogo from "../../images/logo_svg.svg";
import searchIcon from "../../images/search-icon.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import hamburger from "../../images/website-images/ion_menu.svg"

const StyledLink = styled(Link)(({ theme, active }) => ({
  textDecoration: "none",
  fontFamily: "Poppins",
  color: active ? "#353A32" : "inherit",
  fontWeight: active ? "bold" : "regular",
  fontSize: "16px",
  lineHeight: "14px",
  zIndex: 4,
  borderBottom: active ? "1px solid black" : "none",
  "&:hover": {
    color: "#353A32",
  },
}));

const Navbar = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const [activeItem, setActiveItem] = useState(1);
  const location = useLocation();

  const navList = [
    { id: 1, name: "Home", path: "/" },
    { id: 2, name: "About us", path: "/aboutus" },
    { id: 3, name: "Products", path: "/products" },
    { id: 4, name: "Track Order", path: "/trackOrders" },
    { id: 5, name: "Contact", path: "/contact" },
  ];

  useEffect(() => {
    const path = location.pathname;
    const active = navList.find((item) => item.path === path); // Exact match
    setActiveItem(active ? active.id : 1);
  }, [location.pathname]);

  return (
    <Box>
      {/* Navbar */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: { xs: '12px 24px', sm: '16px 24px', md: "16px 24px" },
          background: { xs: 'radial-gradient(circle at top left, #71ca9b4f, #f6fffa30 35%, rgba(255, 255, 255, 0.5) 50%)', md: 'none' }
          // background: '#fff !important'
        }}
      >
        {/* Logo Section */}
        <Link to='/' style={{color: 'inherit', textDecoration: 'none'}}>
        <Box display="flex" alignItems="center" gap="12px">
          <Box
            component="img"
            src={anahadsidelogo}
            alt="Anahad Logo"
            sx={{ width: "40px", height: "40px" }}/>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#353A32"
            textTransform="uppercase"
            fontFamily="'MuktaExtraBold', sans-serif"
          >
            Anahad
          </Typography>
        </Box>
        </Link>
        {/* Desktop Navigation */}
        <List
          sx={{
            display: { xs: "none", md: "flex" },
            gap: "48px",
            fontFamily: "Poppins",
          }}
        >
          {navList.map((nav) => (
            <StyledLink key={nav.id} to={nav.path} active={activeItem === nav?.id}>
              <ListItem
                disablePadding
                sx={{
                  width: "auto",
                }}
              >
                {nav.name}
              </ListItem>
            </StyledLink>
          ))}
        </List>
        {/* Search Box */}
        <Box
          position="relative"
          width="265px"
          height="36px"
          sx={{ display: { xs: "none", md: "block" } }}
        >
          <Box
            component="img"
            src={searchIcon}
            alt="search-icon"
            position="absolute"
            top="11px"
            left="14px"
            width="15px"
            zIndex={3}
          />
          <input
            type="text"
            style={{
              background: "linear-gradient(45deg, #d4efe1, #e6faf0)",
              fontSize: "14px",
              borderRadius: "24px",
              padding: "8px 16px",
              paddingLeft: "36px",
              border: "2px solid #fff",
              width: "205px",
              boxShadow: "rgb(62 62 69 / 29%) 0px 2px 6px",
            }}
            onFocus={(e) => (e.target.style.outline = "none")}
            onBlur={(e) => (e.target.style.outline = "none")}
          />
        </Box>

        {/* Hamburger Menu for Mobile */}
        <IconButton
          sx={{
            display: { xs: "block", md: "none" },
            transform: isDrawerOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
          onClick={() => setIsDrawerOpen((prev) => !prev)} // Toggle the drawer state
        >
          {isDrawerOpen ? <CloseIcon /> : <Box component={'img'} src={hamburger} />}
        </IconButton>
      </Box>

      {/* Drawer Section */}
      <Box
        sx={{
          position: "absolute",
          top: "12%",
          left: 0,
          width: "100%",
          background: "#f8f8f8",
          height: isDrawerOpen ? "255px" : "0",
          overflow: "hidden",
          transition: "height 0.3s ease",
          boxShadow: isDrawerOpen ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
        }}
      >
        <Box sx={{ padding: "16px" }}>
          {navList.map((nav) => (
            <Link to={nav.path} style={{ textDecoration: "none", color: "inherit" }}>
              <Typography
                key={nav.id}
                sx={{
                  paddingBottom: "16px",
                  fontFamily: "Poppins",
                  textDecoration: "none",
                  color: "#353A32",
                  fontWeight: activeItem === nav?.id ? "bold" : "regular",
                  fontSize: "16px",
                  "&:hover": { color: "#3182CE" },
                }}
                onClick={() => setIsDrawerOpen(false)} // Close drawer when a link is clicked
              >
                {nav.name}
              </Typography>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
