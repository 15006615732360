import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";

// Custom LinearProgress Bar
const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  width: "126px",
  borderRadius: "4px",
  backgroundColor: "#eeeeee", // Background of the progress bar
  "& .MuiLinearProgress-bar": {
    background: "#71CA9B", // Green gradient
  },
}));

const OrderStatusProgress = ({ status }) => {
  // Mapping order status to progress percentage
  const statusToPercentage = {
    "Bill Generated": 0,
    "Preparing Dispatch": 25,
    "Goods Dispatched": 50,
    "Out for Delivery": 75,
    "Delivered": 100,
  };

  // Get the percentage value for the current status
  const value = statusToPercentage[status] || 0; // Default to 0 if status is unknown
  const markers = [0, 25, 50, 75, 100]; // Marker positions

  return (
    <Box>
      {/* Display Percentage */}
      <Typography variant="h6" color="#71CA9B" fontWeight="bold" fontSize="14px">
        {value}%
      </Typography>

      {/* Progress Bar */}
      <Box flex="1" position="relative">
        <CustomLinearProgress variant="determinate" value={value} />

        {/* Dots (Markers) */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          display="flex"
          gap="27px"
          transform="translateY(-50%)"
        >
          {markers.map((mark, index) => (
            <Box
              key={index}
              width={4}
              height={4}
              borderRadius="50%"
              bgcolor={value >= mark ? "#49a981" : "#A9B1BC"} // Fill active dots with green
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderStatusProgress;
