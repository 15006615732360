import { Box, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import admin_icon from '../images/person.png';
import settings_icon from '../images/settings.png';
import notification_icon from '../images/notification.png';
import calenderImg from '../images/uis_calender.png';

const DashboardHeader = ({ onDateChange })=>{
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
  
    // States to control the open/close state of the DatePicker
    const [fromOpen, setFromOpen] = useState(false);
    const [toOpen, setToOpen] = useState(false);
  
    // Refs for the calendar icons
    // const fromDatePickerRef = useRef(null);
    // const toDatePickerRef = useRef(null);
    const handleDateChange = (type, value) => {
      if (type === "from") {
          setFromDate(value || dayjs()); // Default to today if `value` is null
          onDateChange?.(value || dayjs(), toDate); // Callback with default values
      } else {
          setToDate(value || dayjs()); // Default to today if `value` is null
          onDateChange?.(fromDate, value || dayjs()); // Callback with default values
      }
  };
    return(
        <>
         <Stack direction='row' justifyContent='space-between' alignItems='baseline'>
        <Box pt='16px'>
          <Box flexDirection='row' display='flex' alignItems='center' gap='3px'>
            <Typography variant="h6" sx={{ color: '#a0aec0', fontSize: '14px' }}>
              Pages
            </Typography>
            <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>/</Typography>
            <Typography variant="h6" sx={{ color: 'black', fontSize: '14px' }}>  Dashboard</Typography>
          </Box>
          <Typography mt={1} fontSize='14px' fontWeight='bold' color="#2D3748">Dashboard</Typography>
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box display="flex" alignItems="center" justifyContent="end" gap="16px" p={2} width="100%">
            {/* From Date Picker */}
            <Box display="flex" alignItems="center" gap="16px">
              <Typography fontWeight="bold" fontSize="12px">
                From
              </Typography>
              <DatePicker
                value={fromDate}
                onChange={(newValue) => handleDateChange("from", newValue)}
                open={fromOpen}
                onOpen={() => setFromOpen(true)}
                onClose={() => setFromOpen(false)}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFromOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Box
                            component="img"
                            src={calenderImg}
                            alt="calendar_icon"
                            sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                          />
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      color: "white",
                      backgroundColor: "#71CA9B",
                      borderRadius: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "15px",
                        color: "white",
                        height: "39px",
                        width: "161px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  },
                }}
              />
            </Box>

            {/* To Date Picker */}
            <Box display="flex" alignItems="center" gap="16px">
              <Typography fontWeight="bold" fontSize="12px">
                To
              </Typography>
              <DatePicker
                value={toDate}
                onChange={(newValue) => handleDateChange("to", newValue)}
                open={toOpen}
                onOpen={() => setToOpen(true)}
                onClose={() => setToOpen(false)}
                slots={{
                  textField: TextField,
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            setToOpen(true);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <Box
                            component="img"
                            src={calenderImg}
                            alt="calendar_icon"
                            sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                          />
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      backgroundColor: "#71CA9B",
                      borderRadius: "15px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "15px",
                        color: "white",
                        height: "39px",
                        width: "161px",
                        fontSize: "14px",
                        fontWeight: "bold",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    },
                  },
                }}
              />
            </Box>

          </Box>
        </LocalizationProvider>
        <Box display='flex' gap='20px' alignItems='center'>
          <Stack direction='row' alignItems={'baseline'} gap='3px'>
            <Box component={'img'} src={admin_icon} alt='user' sx={{ width: '12px', height: '12px' }} />
            <Typography fontSize='12px' fontWeight='bold' color='#718096'>Admin</Typography>
          </Stack>
          <Box component={'img'} src={settings_icon} alt='settings' sx={{ width: '12px', height: '12px' }} />
          <Box component={'img'} src={notification_icon} alt='notification' sx={{ width: '12px', height: '12px' }} />
        </Box>
      </Stack>
        </>
    )
}

export default DashboardHeader