import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Card, CardContent, FormControl, InputAdornment, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import anahad_background_img from "../../images/Background.png";
import admin_icon_white from '../../images/user_icon_white.png';
import settings_icon_white from '../../images/setting_icon_white.png';
import bell_icon_white from '../../images/bell_icon_white.png';
import overview_icon from "../../images/overview_icon.png";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import calenderImg from '../../images/uis_calender.png';
import dayjs from "dayjs";
import PharmacyOrdersChart from "../Charts/PharmacyOrdersChart";
import CustomBarChart from "../Charts/PharmacyOrdersChart";
import check_img from "../../images/circle-check.png";
import searchIcon from '../../images/search-icon.png'
import { Link, useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import Config from "../../utils/Config";

const PharmacyOverview = () => {
    const [fromDate, setFromDate] = useState(dayjs());
    const [toDate, setToDate] = useState(dayjs());
    const [fromOpen, setFromOpen] = useState(false);
    const [toOpen, setToOpen] = useState(false);
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);

    const [searchQuery, setSearchQuery] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [pharmacyDetails, setPharmacyDetails] = useState([]);
    const [oneDaysales, setOneDaySales] = useState({ apiData: [] });
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [chartData, setChartData] = useState({
        dailyData: [],
        totalOrders: 0,
        totalBalance: 0,
    });
    const { apiToken } = useContext(AuthContext);
    const { pharmaId } = useParams();

    const formatName = (pName) => {
        if (!pName) return "";
        return pName
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };
    const handleChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const getPharmacyDetails = async () => {
        try {
            const response = await axios.get(Config.Get_Pharmacy_details, {
                params: {
                    pharmacyId: pharmaId
                },
                headers: {
                    Authorization: `Bearer ${apiToken}`
                }
            })
            if (response?.status === 200) {
                setPharmacyDetails(response?.data?.apiData)
                console.log(response?.data?.apiData, 'pharmacyDetails')
            }

        } catch (error) {
            console.log(error, "Error in fetching api response...")
        }
    }

    useEffect(() => {
        getPharmacyDetails();
    }, [pharmaId]);

    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                month: selectedMonth.toLowerCase(),
                CId: pharmaId,
                year: selectedYear,
            };

            try {
                const response = await axios.post(Config?.Get_Pharmacy_sales, requestData, {
                    headers: {
                        Authorization: `Bearer ${apiToken}`,
                    },
                });

                if (response.data.status === 200 && response.data.apiData) {
                    const { totalOrders, totalBalance, dailyData } = response.data.apiData;

                    // Transform dailyData for the chart
                    const transformedData = dailyData.map((item) => ({
                        date: new Date(item.date).getDate(), // Display only the day number (1, 2, 3, ...)
                        fullDate: item.date,
                        value: item.totalBalance,
                    }));

                    // Update state while preserving chart data
                    setChartData({
                        dailyData: transformedData, // For chart
                        totalOrders,               // For display in cards
                        totalBalance,              // For display in cards
                    });
                } else {
                    console.log('Something went wrong');
                }
            } catch (error) {
                console.error('Error fetching API data:', error);
            }
        };

        fetchData();
    }, [selectedMonth,selectedYear, pharmaId, apiToken]);

    const fetchBarData = async (fullDate) => {
        try {
            const response = await axios.post(
                Config?.Get_one_daySales,
                {
                    orderDate: fullDate,
                    CId: pharmaId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${apiToken}`,
                    },
                }
            );

            if (response.data.status === 200) {
                console.log('API response:', response);

                // Set the one-day sales data
                setOneDaySales(response?.data);

                console.log(response?.data?.apiData, 'onedaysaledata')

                // Calculate total balance from all objects in apiData
                const totalBalance = response?.data?.apiData?.reduce(
                    (total, data) => total + data?.Balance,
                    0
                );
                console.log(totalBalance, 'totalBalance'); // Log the total balance

                // You can update the state or handle the total balance here
                setChartData((prevData) => ({
                    ...prevData,
                    totalBalance, // Update the totalBalance in chartData state
                }));
            } else {
                console.log('Something went wrong with the API call');
            }
        } catch (error) {
            console.error('Error fetching API data:', error);
        }
    };



    // const handleDateChange = (from, to) => {
    //     setFromDate(from);
    //     setToDate(to);
    // };
    const handleDateChange = (type, value) => {
        if (type === "from") {
            setFromDate(value || dayjs()); // Default to today if `value` is null
            //   onDateChange?.(value || dayjs(), toDate); 
        } else {
            setToDate(value || dayjs()); // Default to today if `value` is null
            //   onDateChange?.(fromDate, value || dayjs()); 
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleViewMore = () => {
        setIsExpanded(true)
    };
    const generateYears = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 2024;
        const years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    
    return (
        <>
            <Box width='100%' position='relative'>
                <Box
                    sx={{
                        backgroundImage: `url(${anahad_background_img})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        height: '200px',
                        width: '100%',
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px",
                        position: "relative",
                    }}>

                    <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
                        <Box>
                            <Typography variant="caption" color="white">
                                Pages / Pharmacy
                            </Typography>
                            <Typography variant="h5" color="white" fontWeight="bold" mt={1}>
                                Pharmacy details
                            </Typography>
                        </Box>
                        <Box display='flex' gap='12px'>

                            {/* Sign in and settings icons */}
                            <Box display='flex' gap='20px' alignItems='center'>
                                <Stack direction='row' alignItems={'baseline'} gap='3px'>
                                    <Box component={'img'} src={admin_icon_white} alt='user' sx={{ width: '12px', height: '12px' }} />
                                    <Typography fontSize='12px' fontWeight='bold' color='#fff'>Admin</Typography>
                                </Stack>
                                <Box component={'img'} src={settings_icon_white} alt='settings' sx={{ width: '12px', height: '12px' }} />
                                <Box component={'img'} src={bell_icon_white} alt='notification' sx={{ width: '12px', height: '12px' }} />
                            </Box>
                        </Box>
                    </Stack>
                </Box>

                <Card
                    sx={{
                        marginX: "30px",
                        padding: "0px 16px",
                        borderRadius: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        height: '113px',
                        boxShadow: 'none',
                        position: 'absolute',
                        top: '166px',
                        opacity: '0.9',
                        width: '95%'
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Avatar
                            sx={{ width: 64, height: 64, backgroundColor: "#eee" }}
                            variant="rounded"
                        >
                        </Avatar>
                        <Box>
                            <Typography variant="h6" fontSize='18px' color='#2D3748' fontWeight='bold'>{formatName(pharmacyDetails?.name)}</Typography>
                            <Typography variant="body2" color="#718096" fontSize='14px'>
                                {formatName(pharmacyDetails?.address)}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Overview button */}
                    <Box sx={{ backgroundColor: '#ffffff !important' }}>
                        <Button color="#2D3748" sx={{ backgroundColor: 'white !important', padding: '10px 25px', borderRadius: "12px", fontSize: '10px', fontWeight: 'bold', color: '#2D3748', gap: '5px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06)' }}>
                            <Box component={'img'} src={overview_icon} alt='Overicon' sx={{ width: '11px', height: '11px' }} />
                            Overview
                        </Button>
                    </Box>
                </Card>

                <Box mt='5rem'>
                    <Stack direction="row" spacing={2}>
                        {/* Pharmacy Info Card */}
                        <Card sx={{ minWidth: 275, flex: 1, borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1' }}>
                            <CardContent sx={{ padding: '20px 35px 24px' }}>
                                <Typography variant="h6" color="#48505c" textTransform='uppercase' fontSize='14px' fontWeight='bold' marginBottom='15px' gutterBottom>
                                    Pharmacy Details
                                </Typography>
                                {/* <Divider sx={{ my: 1 }} /> */}
                                <Box display='flex' flexDirection='column' gap='13px' ml='12px'>
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Full Name: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{formatName(pharmacyDetails?.name)}</span></Typography>
                                    {/* <Typography variant="body1" ><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Invoice Number: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> 123 1234 123</span></Typography> */}
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Contact: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> {pharmacyDetails?.contactNumber}</span></Typography>
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Address: </strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>{formatName(pharmacyDetails?.address)}</span></Typography>
                                </Box>
                            </CardContent>
                        </Card>

                        {/* Order Info Card */}
                        <Card sx={{ minWidth: 275, flex: 1, borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1' }}>
                            <CardContent sx={{ padding: '20px 0px 24px 35px' }}>
                                <Typography variant="h6" color="#48505c" textTransform='uppercase' fontSize='14px' fontWeight='bold' marginBottom='15px' gutterBottom>
                                    ONGOING ORDER DETAILS
                                </Typography>
                                {/* <Divider sx={{ my: 1 }} /> */}
                                {/* <Box display='flex' flexDirection='column' gap='13px'>
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Order Date:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}>10/02/12 </span></Typography>
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Order Time:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> 3:00 pm </span></Typography>
                                    <Typography variant="body1"><strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Delivery Date:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> 3:00 pm </span></Typography>
                                    <Typography variant="body1"> <strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold' }}>Status:</strong> <span style={{ color: '#898989', fontSize: '14px', marginLeft: '10px' }}> Out for delivery</span></Typography>
                                </Box> */}
                                <Box display="flex" gap="13px" height='101px' overflow='hidden' sx={{
                                    overflowY: 'auto',
                                    width: '100%',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                        height: '12px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#d9d9d9',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#4A5568',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#fff',
                                    },
                                }}>
                                    <Stack display='flex' flexDirection='row' pr='6rem' justifyContent='space-between' width='100%'>
                                        <Typography variant="body1" >
                                            <strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', mb: '8px' }}>Invoice Number</strong>
                                            <Box display="flex" flexDirection="column" marginLeft="10px" sx={{cursor: 'pointer'}}>
                                                {pharmacyDetails.orders?.map((order, index) => (
                                             <Link to={`/orderdetails/${encodeURIComponent(order.InvNo)}`} style={{ textDecoration: 'none', color: 'inherit' }} >
                                                    <span key={`time-${index}`} style={{ color: '#A0AEC0', fontSize: '14px' , paddingBottom: '4px'}}>
                                                        {order.InvNo}
                                                    </span>
                                            </Link>
                                                ))}
                                            </Box>
                                        </Typography>
                                        <Typography variant="body1">
                                            <strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', mb: '8px' }}>Order Dates</strong>
                                            <Box display="flex" flexDirection="column" marginLeft="10px">
                                                {pharmacyDetails.orders?.map((order, index) => (
                                                    <span key={`date-${index}`} style={{ color: '#A0AEC0', fontSize: '14px' ,paddingBottom: '4px'}}>
                                                        {new Date(order.OrderDate).toLocaleDateString()}
                                                    </span>
                                                ))}
                                            </Box>
                                        </Typography>

                                        {/* Display all order times, each on a new line */}
                                        <Typography variant="body1">
                                            <strong style={{ color: '#808080', fontSize: '14px', fontWeight: 'bold', mb: '8px' }}>Order Times</strong>
                                            <Box display="flex" flexDirection="column" marginLeft="10px">
                                                {pharmacyDetails.orders?.map((order, index) => (
                                                    <span key={`time-${index}`} style={{ color: '#A0AEC0', fontSize: '14px' ,paddingBottom: '4px'}}>
                                                        {new Date(order.OrderDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                    </span>
                                                ))}
                                            </Box>
                                        </Typography>
                                    </Stack>
                                </Box>

                            </CardContent>
                        </Card>
                    </Stack>
                    <Stack marginTop='20px' direction='row' gap='22px'>
                        <Box width='49%'>
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <Box display="flex" alignItems="center" justifyContent="flex-start" gap="16px" p={2} width="95%">
                                    <Box display="flex" alignItems="center" gap="16px">
                                        <Typography fontWeight="bold" fontSize="12px">
                                            From
                                        </Typography>
                                        <DatePicker
                                            value={fromDate}
                                            onChange={(newValue) => handleDateChange("from", newValue)}
                                            open={fromOpen}
                                            onOpen={() => setFromOpen(true)}
                                            onClose={() => setFromOpen(false)}
                                            slots={{
                                                textField: TextField,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setFromOpen(true);
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    src={calenderImg}
                                                                    alt="calendar_icon"
                                                                    sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                    sx: {
                                                        color: "white",
                                                        backgroundColor: "#71CA9B",
                                                        borderRadius: "15px",
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "15px",
                                                            color: "white",
                                                            height: "39px",
                                                            width: "161px",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>

                                    <Box display="flex" alignItems="center" gap="16px">
                                        <Typography fontWeight="bold" fontSize="12px">
                                            To
                                        </Typography>
                                        <DatePicker
                                            value={toDate}
                                            onChange={(newValue) => handleDateChange("to", newValue)}
                                            open={toOpen}
                                            onOpen={() => setToOpen(true)}
                                            onClose={() => setToOpen(false)}
                                            slots={{
                                                textField: TextField,
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment
                                                                position="end"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setToOpen(true);
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                            >
                                                                <Box
                                                                    component="img"
                                                                    src={calenderImg}
                                                                    alt="calendar_icon"
                                                                    sx={{ width: "20px", height: "20px", objectFit: "contain" }}
                                                                />
                                                            </InputAdornment>
                                                        ),
                                                    },
                                                    sx: {
                                                        backgroundColor: "#71CA9B",
                                                        borderRadius: "15px",
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: "15px",
                                                            color: "white",
                                                            height: "39px",
                                                            width: "161px",
                                                            fontSize: "14px",
                                                            fontWeight: "bold",
                                                        },
                                                        "& .MuiOutlinedInput-notchedOutline": {
                                                            border: "none",
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                    </Box>

                                </Box>
                            </LocalizationProvider> */}
                            <FormControl fullWidth variant="outlined" style={{ minWidth: 200, width: '10%', marginBottom: '14px' , marginRight: '2rem'}}>
                                <InputLabel id="month-select-label">Month</InputLabel>
                                <Select
                                    labelId="month-select-label"
                                    value={selectedMonth}
                                    onChange={handleChange}
                                    label="Month"
                                >
                                    {[
                                        'January',
                                        'February',
                                        'March',
                                        'April',
                                        'May',
                                        'June',
                                        'July',
                                        'August',
                                        'September',
                                        'October',
                                        'November',
                                        'December',
                                    ].map((month, index) => (
                                        <MenuItem key={index} value={month}>
                                            {month}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ minWidth: 200, width: '10%', marginBottom: '14px' }}>
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                    labelId="year-select-label"
                    value={selectedYear}
                    onChange={handleYearChange}
                    label="Year"
                >
                    {generateYears().map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
                            <CustomBarChart selectedMonth={selectedMonth} chartData={chartData.dailyData} fetchBarData={fetchBarData} />
                        </Box>
                        <Box width="49%">
                            <Stack direction='row' spacing={2} width='100%'>
                                <Card sx={{ width: '49%', boxShadow: '0px 2px 3px #ebebeb', borderRadius: '8px' }} style={{ marginTop: '4.5rem' }}>
                                    <CardContent sx={{ padding: '16px !important' }}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                            <Box>
                                                <Typography sx={{ fontFamily: "Helvetica", fontWeight: 600, fontSize: 14, color: '#A0AEC0' }}>Order Quantity</Typography>
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', gap: '22px' }}><span style={{ color: '#2D3748', fontFamily: "Helvetica", fontWeight: 600, fontSize: 18 }}>  
                                                {oneDaysales?.apiData?.length > 0 ? oneDaysales.apiData.length : chartData?.totalOrders}
                                                     </span>
                                                {/* <span style={{ color: '#48BB78', fontFamily: "Helvetica", fontWeight: 600 }}>+55%</span> */}
                                                </Typography>
                                            </Box>
                                            <Box width='45px' height='39px' sx={{ backgroundColor: '#71CA9B', borderRadius: '10px' }} />
                                        </Stack>
                                    </CardContent>
                                </Card>
                                <Card sx={{ width: '49%', boxShadow: '0px 2px 3px #ebebeb', borderRadius: '8px' }} style={{ marginTop: '4.5rem' }}>
                                    <CardContent sx={{ padding: '16px !important' }}>
                                        <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                            <Box>
                                                <Typography sx={{ fontFamily: "Helvetica", fontWeight: 600, fontSize: 14, color: '#A0AEC0' }}>Order Value</Typography>
                                                <Typography sx={{ display: 'flex', justifyContent: 'space-between', gap: '22px' }}>
                                                    <span style={{ color: '#2D3748', fontFamily: "Helvetica", fontWeight: 600, fontSize: 18 }}>
                                                        {oneDaysales && oneDaysales[0]?.Balance !== undefined ? oneDaysales[0]?.Balance : chartData?.totalBalance}
                                                    </span>
                                                    {/* <span style={{ color: '#48BB78', fontFamily: "Helvetica", fontWeight: 600 }}>+55%</span> */}
                                                    </Typography>
                                            </Box>
                                            <Box width='45px' height='39px' sx={{ backgroundColor: '#71CA9B', borderRadius: '10px' }} />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Stack>
                            <Box width='93%' sx={{ background: 'white', padding: '20px 20px 24px', borderRadius: '15px', boxShadow: '1px 2px 10px #f1f1f1', height: 'fit-content', marginTop: '20px' }}>
                                <Stack direction='row' justifyContent='space-between'>
                                    <Box>
                                        <Typography fontWeight='bold' fontSize='18px' color='#2D3748' gutterBottom>Order Items</Typography>
                                        {/* <Stack direction='row' alignItems='center' gap='5px'>
                                            <Box component={'img'} src={check_img} alt='check-icon' width={15} height={15} />
                                            <Typography fontSize='14px' fontWeight='bold' color='#A0AEC0'>30 done</Typography>
                                            <Typography fontSize='14px' fontWeight='normal' color='#A0AEC0'>this month</Typography>
                                        </Stack> */}
                                    </Box>
                                    {/* <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            background: "#ffffff",
                                            padding: "5px 10px",
                                            borderRadius: "15px",
                                            border: '1px solid #E2E8F0',
                                            width: '190px',
                                            height: '28px'
                                        }}>
                                        <Box component={'img'} src={searchIcon} />
                                        <input
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            placeholder="Search Pharmacy"
                                            style={{
                                                border: "none",
                                                background: "transparent",
                                                marginLeft: "10px",
                                                outline: "none",
                                                color: 'gray.400'
                                            }} />
                                    </Box> */}
                                </Stack>
                                <TableContainer>
            {/* Table Head */}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#A0AEC0',
                                borderBottom: 'none',
                            }}
                        >
                            <strong>Items</strong>
                        </TableCell>
                        <TableCell
                            sx={{
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#A0AEC0',
                                textAlign: 'center',
                                borderBottom: 'none',
                            }}
                        >
                            <strong>Quantity</strong>
                        </TableCell>
                        <TableCell
                            sx={{
                                fontSize: '10px',
                                fontWeight: 'bold',
                                color: '#A0AEC0',
                                textAlign: 'center',
                                borderBottom: 'none',
                            }}
                        >
                            <strong>Value</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>

            {/* Scrollable Body and Footer */}
            <Box
                sx={{
                    maxHeight: isExpanded ? 'none' : '215px',
                    overflowY: isExpanded ? 'unset' : 'auto',
                    width: '100%',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        height: '12px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#d9d9d9',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#4A5568',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#fff',
                    },
                }}
            >
                <Table>
                    <TableBody>
                        {oneDaysales?.apiData?.length > 0 &&
                            oneDaysales.apiData.map((data, index) =>
                                data.products.map((item, productIndex) => (
                                    <TableRow key={`${index}-${productIndex}`}>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#2d3748',
                                                borderBottom: '1px solid #E2E8F0',
                                            }}
                                        >
                                            {formatName(item.PName)}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#2d3748',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #E2E8F0',
                                            }}
                                        >
                                            {item.Qty}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: '#2d3748',
                                                textAlign: 'center',
                                                borderBottom: '1px solid #E2E8F0',
                                            }}
                                        >
                                            {item.GrsAmt.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell
                                sx={{
                                    fontSize: '10px',
                                    fontWeight: 'bold',
                                    color: '#A0AEC0',
                                    borderBottom: 'none',
                                }}
                            >
                                Total
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#2d3748',
                                    textAlign: 'center',
                                    borderBottom: 'none',
                                }}
                            >
                                {oneDaysales?.apiData?.reduce(
                                    (total, data) =>
                                        total + data?.products?.reduce(
                                            (productTotal, item) => productTotal + item.Qty,
                                            0
                                        ),
                                    0
                                )}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    color: '#2d3748',
                                    textAlign: 'center',
                                    borderBottom: 'none',
                                }}
                            >
                                {oneDaysales?.apiData?.reduce(
                                    (total, data) =>
                                        total + data?.products?.reduce(
                                            (productTotal, item) => productTotal + item.GrsAmt,
                                            0
                                        ),
                                    0
                                ).toFixed(2)}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Box>

            {!isExpanded && (
                <Box textAlign="end" padding="0px 16px" marginTop="10px">
                    <Button
                        onClick={handleViewMore}
                        sx={{
                            fontSize: '10px',
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: '#687688',
                            borderColor: 'transparent',
                            padding: '0px 35px',
                        }}
                    >
                        View More
                    </Button>
                </Box>
            )}
        </TableContainer>
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default PharmacyOverview