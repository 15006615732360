import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import managementImg from "../../images/website-images/commercial_1.svg"
import catelougeImg from "../../images/website-images/catalogue_1.svg"
import commercialImg from "../../images/website-images/management.svg"

const FeatureCards = () => {
  const features = [
    {
      text: "A diverse catalog of trusted brands.",
      bgColor: "#fff",
      iconBgColor: "#71CA9B",
      textColor: "#36463F",
      icon: `${catelougeImg}`,
      width: '40px'
    },
    {
      text: "Seamless ordering and delivery processes.",
      bgColor: "#fff",
      iconBgColor: "#71CA9B",
      textColor: "#36463F",
      icon: `${managementImg}`,
      width: '32px'
    },
    {
      text: "Data-driven insights for operational improvements.",
      bgColor: "#fff",
      iconBgColor: "#71CA9B",
      textColor: "#36463F",
      icon: `${commercialImg}`,
      width: '32px'
    },
  ];

  return (
    <Box width="80%" margin="auto" padding={{ xs: '1.3rem', md: "3rem" }} bgcolor="#36463F" borderRadius='10px'>
      <Stack direction={{ xs: 'column', sm: 'row', md: "row" }} flexWrap='wrap' justifyContent='space-between' gap={{ xs: '22px' }}>
        {features.map((feature, index) => (
          <Box
            key={index}
            padding={{ xs: '22px 0px 28px', md: "32px 10px" }}
            borderRadius='10px'
            width={{ xs: '99%', sm: '26%', md: '27%' }}
            bgcolor={feature.bgColor}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{
              width: "50px",
              height: "50px",
              borderRadius: "50px",
              background: feature.iconBgColor,
              display: 'flex', justifyContent: 'center', alignItems: 'center'
            }}>
              <Box
                component={'img'}
                src={feature?.icon}
                sx={{
                  width: feature?.width,
                }}
              />
            </Box>
            <Typography
              sx={{
                color: feature.textColor,
                fontSize: "22px",
                fontFamily: "'GeneralSansMedium', sans-serif",
                textAlign: "center",
                marginTop: "10px",
                width: '87%',
                textTransform: 'capitalize'
              }}
            >
              {feature.text}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default FeatureCards;
