import { Box } from "@mui/material";
import React from "react";
import Sidebar from "../components/Sidebar";
import PharmacyOverview from "../components/PharmacyOverview/PharmacyOverview";


const Overview = () => {
    return (
        <>
            <Box display='flex' padding='0px 20px 20px 10px' sx={{ backgroundColor: '#f9f9f9' }}>
                <Sidebar />
                <Box p={'16px'} width={'100%'} >
                    <PharmacyOverview />
                </Box>
            </Box>
        </>
    )
}

export default Overview